import { useEffect } from 'react';

export const useBrowserNavigationUnload = (callback: () => void) => {
  useEffect(() => {
    window.addEventListener('beforeunload', callback);

    return () => {
      window.removeEventListener('beforeunload', callback);
    };
  }, [callback]);
};

import React, { ReactNode } from 'react';

import { styled } from '@mui/material';

import { cssVars, ResponsiveGrid } from '@mewa/ui';

import {
  CONTAINER_REMOVE_ANIMATION_TIME,
  CONTAINER_REMOVE_DELAY,
} from './animation-constants';

type Props = {
  show: boolean;
  renderUI: boolean;
  showUI: boolean;
  onMouseEnter: () => void;
  children: ReactNode;
};

const MenuWrapper = ({ showUI, renderUI, children, onMouseEnter }: Props) => {
  // return early if we don't want to render the UI anymore
  if (!renderUI) {
    return <span />;
  }

  return (
    <MenuContainer
      onMouseEnter={onMouseEnter}
      style={{
        opacity: showUI ? 1 : 0,
        transitionDelay: showUI ? '200ms' : `${CONTAINER_REMOVE_DELAY}ms`,
      }}
    >
      <ResponsiveGrid
        sx={{
          maxHeight: 'calc(100vh - var(--header-height))',
          overflow: 'auto', // just in case the window height is very small
        }}
      >
        {children}
      </ResponsiveGrid>
    </MenuContainer>
  );
};

export default MenuWrapper;

const MenuContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'var(--color-white)',
  boxShadow: '0px 59px 47px -27px rgba(0, 0, 0, 0.1)',
  borderBottomLeftRadius: '2px',
  borderBottomRightRadius: '2px',
  transition: `opacity ${CONTAINER_REMOVE_ANIMATION_TIME}ms linear`,
  overflow: 'hidden',
  position: 'relative',
  justifyContent: 'flex-end !important',
  alignItems: 'flex-end !important',
  paddingTop: '47px',
  paddingBottom: '64px',

  [theme.breakpoints.down(cssVars.headerMobileMaxWidth)]: {
    display: 'none',
  },
}));

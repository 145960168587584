import React, { useEffect } from 'react';

import { Market } from '@mewa/types';
import {
  ContactTeaser,
  ContactTeaserLabelProps,
  ContactTeaserVariantB,
} from '@mewa/ui';

import { useBrowserNavigationUnload } from '../hooks/use-browser-navigation-unload';
import * as contactTeaserExperiment from './contact-teaser-experiment';
import { useContactTeaserVariant } from './contact-teaser-experiment';

export const ContactTeaserVariantSelection = ({
  market,
  conversionCallback,
  hotlineCallback,
  contactTeaserLabels,
}: {
  market: Market;
  conversionCallback: () => void;
  hotlineCallback: () => void;
  contactTeaserLabels: Record<keyof ContactTeaserLabelProps, string>;
}) => {
  const { contactTeaserVariant, clickOnConversion, navigateAwayFromPage } =
    useContactTeaserVariant(market);

  useBrowserNavigationUnload(navigateAwayFromPage);

  // on unmount homepage
  useEffect(() => navigateAwayFromPage, []);

  return contactTeaserVariant ===
    contactTeaserExperiment.ContactTeaserVariant.new ? (
    <ContactTeaserVariantB
      labels={contactTeaserLabels}
      onClickConsultation={() => clickOnConversion(true, conversionCallback)}
      onClickHotline={hotlineCallback}
    />
  ) : (
    <ContactTeaser
      labels={contactTeaserLabels}
      onClickConsultation={() => clickOnConversion(true, conversionCallback)}
      onClickHotline={hotlineCallback}
    />
  );
};

import React from 'react';

import { Button, Grid, styled } from '@mui/material';

import { ContactPhone, EmailIcon } from '../../../../assets/icons';
import ResponsiveGrid from '../../../layout/grid';
import { ContactTeaserLabelProps } from './contact-teaser';

type Props = {
  labels: ContactTeaserLabelProps;
  onClickConsultation: () => void;
  onClickHotline: () => void;
  className?: string;
};

export const ContactTeaserVariantB = React.memo(
  ({
    labels: {
      consultationTitle,
      consultationSubTitle,
      consultationCTALabel,
      contactTitle,
      contactSubTitle,
      openingHours,
      phoneNumber,
    },
    onClickConsultation,
    onClickHotline,
    className = '',
  }: Props) => {
    const ConsultationContent = ({
      variant,
    }: {
      variant: 'mobile' | 'desktop';
    }) => (
      <ContactBox>
        <IconWrapper>
          <MailIcon />
        </IconWrapper>

        <ContactTitle>{consultationTitle}</ContactTitle>
        <ContactText>{consultationSubTitle}</ContactText>

        {variant === 'mobile' && <LinkArea />}
        {variant === 'desktop' && (
          <Button
            onClick={onClickConsultation}
            variant="contained"
            size="medium"
            sx={{
              display: ['none', 'inline-flex'],
              marginTop: '25px',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {consultationCTALabel}
          </Button>
        )}
      </ContactBox>
    );

    const DesktopConsultationBox = styled('div')(({ theme }) => ({
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }));

    const MobileConsultationBox = styled('div')(({ theme }) => ({
      width: '100%',

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }));

    const ConsultationCard = () => (
      <>
        <DesktopConsultationBox>
          <ConsultationContent variant="desktop" />
        </DesktopConsultationBox>

        <MobileConsultationBox>
          <button
            onClick={onClickConsultation}
            style={{
              font: 'unset',
              border: 'none',
              cursor: 'pointer',
              display: 'block',
              width: '100%',
              padding: 0,
            }}
          >
            <ConsultationContent variant="mobile" />
          </button>
        </MobileConsultationBox>
      </>
    );

    const HotlineCard = () => (
      <ContactBox>
        <IconWrapper>
          <PhoneIcon />
        </IconWrapper>

        <ContactTitle isHotline>{contactTitle}</ContactTitle>
        <ContactText>
          {contactSubTitle}
          <br />
          {openingHours}
        </ContactText>

        <PhoneNumberWrapper>
          <PhoneNumberText href={`tel:${phoneNumber}`} onClick={onClickHotline}>
            {phoneNumber}
          </PhoneNumberText>
        </PhoneNumberWrapper>
      </ContactBox>
    );

    return (
      <Container className={className}>
        <Spacer />
        <Grid item style={{ width: '100%' }}>
          <ContactBoxWrapper>
            <HotlineCard />
            <ConsultationCard />
          </ContactBoxWrapper>
        </Grid>
      </Container>
    );
  },
);

export default ContactTeaserVariantB;

const Spacer = () => <Grid item xs={0} sm={1} md={2} lg={3} />;

const MailIcon = styled(EmailIcon)(({ theme }) => ({
  color: 'var(--color-light-red)',
  width: '45px',
  height: '45px',
  marginLeft: '11px',
}));

const PhoneIcon = styled(ContactPhone)(({ theme }) => ({
  color: 'var(--color-light-red)',
  marginLeft: '16px',
  width: '37px',
  height: '37px',

  [theme.breakpoints.only('xs')]: {
    marginLeft: 0,
  },
}));

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '45px',
  backgroundColor: 'var(--color-light-grey)',
  paddingTop: '30px',
  paddingBottom: '10px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '100px',
    paddingTop: '46px',
    paddingBottom: '58px',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '145px',
    paddingTop: '90px',
    paddingBottom: '100px',
  },
}));

const LinkArea = styled('div')(({ theme }) => ({
  display: 'none',
  border: 'none',
  width: '48px',
  backgroundColor: 'transparent',

  [theme.breakpoints.only('xs')]: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ContactBoxWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  gridTemplateColumns: 'repeat(2, minmax(auto, 420px))',
  gridTemplateRows: 'auto',
  justifyContent: 'center',
  width: '100%',
  columnGap: '20px',
  rowGap: '40px',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '100%',
    gridTemplateRows: 'auto',
    rowGap: '15px',
    marginBottom: '30px',
    padding: 0,
  },

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(2, minmax(auto, 560px))',
  },
}));

const ContactBox = styled('div')(({ theme }) => ({
  display: 'grid',
  justifyItems: 'flex-start',
  backgroundColor: 'white',
  gridTemplateRows: '70px auto auto auto',
  padding: '10px 40px 40px 40px',
  height: '100%',

  [theme.breakpoints.only('xs')]: {
    gridTemplateColumns: '70px 1fr',
    gridColumnGap: '10px',
    gridTemplateRows: '1fr',
    justifyItems: 'unset',
    padding: '25px 40px 25px 25px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '10px 50px 50px 50px',
  },
}));

const ContactTitle = styled('p')(
  ({ isHotline = false }: { isHotline?: boolean }) =>
    ({ theme }) => ({
      marginTop: 0,
      textAlign: 'center',
      ...theme.typography.h4,
      color: 'var(--color-dark-coal)',

      [theme.breakpoints.down('sm')]: {
        gridColumn: isHotline ? '2/4' : 'unset',
        display: 'flex',
        margin: 0,
        textAlign: 'start',
        alignItems: 'center',
      },
    }),
);

const ContactText = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginBottom: 0,
  marginTop: 0,
  textAlign: 'left',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PhoneNumberWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.only('xs')]: {
    gridColumn: '2/4',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
}));

const PhoneNumberText = styled('a')(({ theme }) => ({
  ...theme.typography.h6,
  textAlign: 'center',
  color: 'var(--color-light-red)',
  marginTop: '15px',
  marginBottom: '15px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '0px',
    textAlign: 'start',
  },
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    gridRow: '1/3',
    width: '70px',
    height: 'auto',
    alignSelf: 'unset',
    alignItems: 'center',
  },
}));

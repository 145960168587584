import { DataLayer } from '@piwikpro/react-piwik-pro';

import { navigate } from 'gatsby';

export const trackAndNavigate = (to: string) => {
  DataLayer.push({
    event: 'quick_navigation_submit',
  });

  navigate(to);
};

import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import {
  Button,
  buttonClasses,
  ButtonTypeMap,
  Grid,
  styled,
} from '@mui/material';
import { OverrideProps } from '@mui/material/OverridableComponent';

import ResponsiveGrid from '../../layout/grid';

export const StageWithCta = ({
  title,
  subtitle,
  image,
  link,
  piwikEventNameCTA,
  labelCTA,
}: {
  title: string;
  subtitle: React.ReactElement;
  image: React.ReactElement;
  link: {
    component: React.ElementType;
    props: OverrideProps<ButtonTypeMap, React.ElementType>;
  };
  labelCTA: string;
  piwikEventNameCTA: string;
}) => (
  <Container>
    <PositionContainer>
      <ImageContainer>{image}</ImageContainer>
    </PositionContainer>
    <TextContainer>
      <ResponsiveGrid>
        <Grid item xs={12} sm={6}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <ButtonStyleWrapper>
            <Button
              variant="contained"
              color="primary"
              component={link.component}
              {...link.props}
              onClick={() =>
                DataLayer.push({
                  event: piwikEventNameCTA || '',
                })
              }
            >
              {labelCTA}
            </Button>
          </ButtonStyleWrapper>
        </Grid>
      </ResponsiveGrid>
    </TextContainer>
  </Container>
);

const TextContainer = styled('div')(({ theme }) => ({
  marginBottom: '25px',

  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '0',
    zIndex: 1,
    marginBottom: 0,
  },
}));

const PositionContainer = styled('div')(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.only('sm')]: {
    aspectRatio: 375 / 250,
  },
}));

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    aspectRatio: 900 / 350,
  },

  [theme.breakpoints.up('mdSm')]: {
    aspectRatio: 1440 / 450,
  },

  [theme.breakpoints.up('md')]: {
    aspectRatio: 1920 / 500,
  },
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  fontWeight: theme.typography.fontWeightLight,

  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
    color: 'var(--color-white)',
  },
}));

const Subtitle = styled('h4')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: '20px',

  [theme.breakpoints.up('sm')]: {
    color: 'var(--color-white)',
    marginBottom: '30px',
  },
}));

const ImageContainer = styled('div')({
  img: { width: '100%' },
});

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  [`.${buttonClasses.contained}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '18px',
    },
  },
}));

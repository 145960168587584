import { RequestInfo, RequestInit } from 'node-fetch';

import { Language, Market, ServiceSegmentType } from './types';

export type CodeMap<T extends string = string> = Record<T, string>;

export enum CRM_RequestType {
  Consultation = 'consultation',
  OrderService = 'order-service',
  ServiceTest = 'service-test',
  // SlimTest = 'slim-test',
}

export enum LeadIndustry {
  healthcare = 'healthcare',
  craft = 'craft',
  industry = 'industry',
  publicInstitutions = 'publicInstitutions',
  serviceAndCommerce = 'serviceAndCommerce',
}

export enum LeadIntent {
  question = 'question',
  product = 'product',
  consultation = 'consultation',
  recall = 'recall',
}

export const isLeadIntent = (value: unknown): value is LeadIntent =>
  typeof value === 'string' &&
  (Object.values(LeadIntent) as string[]).includes(value);

export const isLeadWithProductCode = (request: LeadGenerationRequestBody) =>
  request.leadIntent === LeadIntent.product ||
  request.leadIntent === LeadIntent.consultation;

export type LeadServiceSegmentCategory =
  | 'MTS'
  | 'MBS'
  | 'MBW'
  | 'MBK'
  | 'MWS'
  | 'MFS';

export type LeadServiceSegmentDataSAP = {
  name?: string;
  segmentCategory: LeadServiceSegmentCategory;
  code: string;
  productCode: string;
};

export type ContactFormType = 'male' | 'female' | 'noInfo';

export type CompanySize = 'small' | 'large';

export type AlreadyCustomer = 'no' | 'yes';

export enum ResponsePreference {
  morning = 'morning',
  afternoon = 'afternoon',
}

export type Brochure = 'brandCatalog' | ServiceSegmentType;

export type ConversionFormData = {
  customer?: {
    id: string | null;
    isAlreadyCustomer: AlreadyCustomer | null;
  };
  company: {
    name: string;
    zip: string;
    size: CompanySize;
  };
  contact: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    responsePreference: Record<ResponsePreference, boolean>;
  };
  notes: string;
  industry?: LeadIndustry | null;
  serviceSegment: ServiceSegmentType | null;
  friendlyCaptchaSolution: string;
  leadIntent?: LeadIntent;
};

type LeadBody<RequestType extends CRM_RequestType> = ConversionFormData & {
  requestType: RequestType;
  language: Language;
  market: Market;
  industry?: LeadIndustry | null;
};

export type ServiceTestRequestBody = LeadBody<CRM_RequestType.ServiceTest>;

export const isServiceTestRequest = (
  request: LeadGenerationRequestBody,
): request is ServiceTestRequestBody =>
  request.requestType === CRM_RequestType.ServiceTest;

export type ConsultationRequestBody = LeadBody<CRM_RequestType.Consultation>;

export const isConsultationRequest = (
  request: LeadGenerationRequestBody,
): request is ConsultationRequestBody =>
  request.requestType === CRM_RequestType.Consultation;

export type BrochuresRequestBody = LeadBody<CRM_RequestType.OrderService> & {
  orderedBrochures: Brochure[];
};

export const isBrochuresRequest = (
  request: LeadGenerationRequestBody,
): request is BrochuresRequestBody =>
  request.requestType === CRM_RequestType.OrderService;

export type LeadGenerationRequestBody =
  | ServiceTestRequestBody
  | ConsultationRequestBody
  | BrochuresRequestBody;

export type LeadQueueMessageBody = {
  originBody: LeadGenerationRequestBody;
  leadXml: string;
  market: Market;
  language: Language;
  requestDate: Date;
  attemptCount?: number;
};

export type CRM_Request = (
  url: RequestInfo,
  init: RequestInit,
) => Promise<number>;

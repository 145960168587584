import React from 'react';

import { styled } from '@mui/material';

export type LabeledIconData = {
  Icon: React.FunctionComponent;
  title?: string | null;
  subtitle?: string | null;
};

export type LabeledIconProps = LabeledIconData & {
  className?: string;
};

export const LabeledIcon = ({
  Icon,
  title,
  subtitle,
  className,
}: LabeledIconProps) => (
  <Container className={className}>
    {Icon && (
      <IconWrapper>
        <Icon />
      </IconWrapper>
    )}
    {title && <Title>{title}</Title>}
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </Container>
);

export default LabeledIcon;

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const IconWrapper = styled('div')(({ theme }) => ({
  width: '70px',
  height: '70px',
  display: 'inline-block',
}));

const Title = styled('h3')(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: '12px',
  marginBottom: 0,
  textAlign: 'center',
}));

const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '5px',
  marginBottom: 0,
  maxWidth: '300px',
  textAlign: 'center',
}));

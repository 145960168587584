import React from 'react';

import { styled, useMediaQuery, useTheme } from '@mui/material';

import { MobileSlider } from '../mobile-slider/mobile-slider';
import { LabeledIcon, LabeledIconData } from './labeled-icon';

type IconGridProps = {
  icons: LabeledIconData[];
  className?: string;
  narrow?: boolean;
};

export const IconGrid = ({
  icons,
  className,
  narrow = false,
}: IconGridProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return isMobile ? (
    <MobileSlider>
      {icons.map((icon, idx) => (
        <StyledLabeledIcon {...icon} key={idx} />
      ))}
    </MobileSlider>
  ) : (
    <Container className={className} narrow={narrow}>
      {icons.map((icon, idx) => (
        <StyledLabeledIcon {...icon} key={idx} />
      ))}
    </Container>
  );
};

export default IconGrid;

const Container = styled('div')(
  ({ narrow }: { narrow: boolean }) =>
    ({ theme }) => ({
      maxWidth: '1120px',
      display: 'grid',
      gridTemplateColumns: `repeat(1, minmax(auto, 320px))`,
      margin: '0 auto',
      rowGap: '25px',

      [theme.breakpoints.down('sm')]: {
        width: '80%',
        justifyContent: 'center',
      },

      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: `repeat(2, 1fr)`,
        columnGap: '20%',
      },

      [theme.breakpoints.up('mdSm')]: {
        gridTemplateColumns: `repeat(${narrow ? 2 : 3}, 1fr)`,
        columnGap: '12%',
      },

      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: `repeat(3, 1fr)`,
        columnGap: '12%',
      },
    }),
);

const StyledLabeledIcon = styled(LabeledIcon)(({ theme }) => ({
  marginTop: '35px',
}));

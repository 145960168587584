import { Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';

export const ImageWithTextOverlay = ({
  image,
  text,
}: {
  image: React.ReactElement;
  text: string;
}) => {
  return (
    <ResponsiveGrid>
      <Grid item xs={12}>
        <Container>
          {image}
          <TextContainer>{text}</TextContainer>
        </Container>
      </Grid>
    </ResponsiveGrid>
  );
};

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const TextContainer = styled('h3')(({ theme }) => ({
  ...theme.typography.h1,
  marginTop: '25px',
  marginBottom: 0,
  textWrap: 'balance',

  [theme.breakpoints.up('sm')]: {
    width: '100%',
    position: 'absolute',
    color: 'var(--color-white)',
    left: 0,
    bottom: 0,
    padding: '200px 60px 60px',
    margin: 0,
    background: `linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.1) 50px,
        rgba(0, 0, 0, 0.45) 180px,
        rgba(0, 0, 0, 0.5) 200px)`,
  },

  [theme.breakpoints.up(1024)]: {
    padding: '200px 80px 80px',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingBottom: '110px',
  },
}));

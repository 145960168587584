import {
  Button,
  buttonClasses,
  ButtonTypeMap,
  ExtendButtonBase,
  styled,
  SxProps,
} from '@mui/material';

export type DownloadButtonProps = {
  label: string;
  url: string;
  className?: string;
  sx?: SxProps;
};

export const DownloadButton = ({
  label,
  url,
  className,
  sx,
}: DownloadButtonProps) => (
  <AnimatedDownloadButton
    startIcon={<DownloadIcon />}
    href={url}
    target="_blank"
    sx={sx}
    className={className}
  >
    {label}
  </AnimatedDownloadButton>
);

const AnimatedDownloadButton = styled(Button)({
  paddingTop: '0 !important',
  paddingBottom: '0 !important',
  marginLeft: '-12px',

  '&::after': {
    opacity: 0,
  },
  'svg .hover-path': {
    transition: '0.2s ease-in-out',
  },
  '&:hover, &:focus': {
    'svg .hover-path': {
      d: 'path("M18 33H30V35H18V33Z")',
    },
  },
  [`.${buttonClasses.focusVisible} svg .hover-path`]: {
    d: 'path("M18 33H30V35H18V33Z")',
  },
}) as ExtendButtonBase<ButtonTypeMap>;

const DownloadIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 21L30.59 19.59L25 25.17V13H23V25.17L17.42 19.58L16 21L24 29L32 21Z"
      fill="#E60021"
    />
    <path className="hover-path" d="M12 33H36V35H12V33Z" fill="#E60021" />
  </svg>
);

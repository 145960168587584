import { useEffect, useState } from 'react';

import { Market } from '@mewa/types';

import {
  evaluateFeature,
  getSessionId,
  getUserId,
  putProjectEvent,
} from './evidently-connection';

export enum ContactTeaserVariant {
  old = 'old_contact_teaser',
  new = 'new_contact_teaser',
}

const DEFAULT_VALUE = ContactTeaserVariant.old;

const experimentData: {
  variant: ContactTeaserVariant | null;
  resultHasBeenSent: boolean;
  isLoadingExperiment: boolean;
  loadingPromise: Promise<ContactTeaserVariant> | null;
} = {
  variant: null,
  resultHasBeenSent: true,
  isLoadingExperiment: false,
  loadingPromise: null,
};

export const useContactTeaserVariant = (market: Market) => {
  const [contactTeaserVariant, setTeaserVariant] = useState(DEFAULT_VALUE);
  const [isLoading, setIsLoading] = useState(market === Market.DE);

  useEffect(() => {
    if (market === Market.DE) {
      getContactTeaserVariant().then((variant) => {
        if (variant !== null) {
          experimentData.resultHasBeenSent = false;
          setTeaserVariant(variant);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [market]);

  const clickOnConversion = (result: boolean, callback: () => void) => {
    sendEvent(result);
    callback();
  };

  const navigateAwayFromPage = () => {
    reset();
  };

  return {
    contactTeaserVariant,
    isLoading,
    clickOnConversion,
    navigateAwayFromPage,
  };
};

export const getContactTeaserVariant =
  async (): Promise<ContactTeaserVariant | null> => {
    if (experimentData.variant !== null) {
      return Promise.resolve(experimentData.variant);
    } else {
      if (!experimentData.isLoadingExperiment) {
        return loadFeature();
      } else if (
        experimentData.isLoadingExperiment &&
        experimentData.loadingPromise !== null
      ) {
        return experimentData.loadingPromise;
      } else {
        return null;
      }
    }
  };

const loadFeature = async (): Promise<ContactTeaserVariant> => {
  const featureName = 'contact_teaser';
  experimentData.isLoadingExperiment = true;

  experimentData.loadingPromise = evaluateFeature(featureName)
    .catch((e) => {
      console.log(`Feature "${featureName}" not found. Using default variant.`);
      return null;
    })
    .then((res) => {
      experimentData.isLoadingExperiment = false;
      experimentData.loadingPromise = null;
      if (res === null || res.value?.stringValue === undefined) {
        return DEFAULT_VALUE;
      } else {
        experimentData.variant = res.value.stringValue as ContactTeaserVariant;
        return experimentData.variant;
      }
    });

  return experimentData.loadingPromise;
};

export const reset = async () => {
  if (!experimentData.resultHasBeenSent) {
    await sendEvent(false);
  }
  experimentData.resultHasBeenSent = false;
};

export const sendEvent = async (result: boolean) => {
  if (!experimentData.resultHasBeenSent) {
    const userId = getUserId();
    const sessionId = getSessionId();

    experimentData.resultHasBeenSent = true;
    const quickNavResult = JSON.stringify({
      details: {
        contactTeaserClicked: result ? 1 : 0,
      },
      userDetails: { userId, sessionId },
    });

    await putProjectEvent(quickNavResult);
  }
};

import React, { PropsWithChildren } from 'react';

import { Grid, styled } from '@mui/material';

import { TextWithMarking } from '@mewa/types';

import ResponsiveGrid from '../../layout/grid';

export const StageWithImageVariantB = ({
  title,
  subtitle,
  image,
  children,
}: PropsWithChildren<{
  title: TextWithMarking;
  subtitle: string;
  image: React.ReactElement;
}>) => (
  <Container>
    <PositionContainer>
      <ImageContainer>
        {image}
        <GradientContainer />
      </ImageContainer>
    </PositionContainer>
    <TextContainer>
      <ResponsiveGrid>
        <Grid item xs={12}>
          <TitleContainer>
            <Title>
              {title.beforeMarking}
              <strong>{title.marking}</strong>
              {title.afterMarking}
            </Title>
            <Subtitle>{subtitle}</Subtitle>
          </TitleContainer>
        </Grid>
      </ResponsiveGrid>
      <ResponsiveGrid>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          {children}
        </Grid>
      </ResponsiveGrid>
    </TextContainer>
  </Container>
);

const Container = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    aspectRatio: '1920 / 750',
  },
}));

const PositionContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    inset: '0 0 0 0',
    zIndex: -1,
  },
}));

const ImageContainer = styled('div')(({ theme }) => ({
  img: { width: '100%' },
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    aspectRatio: '375 / 250',
    overflow: 'hidden',
  },
}));

const GradientContainer = styled('div')(({ theme }) => ({
  background:
    'linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%)',
  inset: '0 50% 0 0',
  position: 'absolute',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  paddingTop: '25px',
  paddingBottom: '25px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  backgroundColor: 'var(--color-light-grey)',

  [theme.breakpoints.up('sm')]: {
    backgroundColor: 'unset',
    color: 'var(--color-white)',
    paddingTop: '29px',
    gap: '40px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '73px',
    gap: '60px',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '93px',
    gap: '80px',
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('mdSm')]: {
    width: '55%',
  },
}));

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h1,
  margin: 0,

  strong: {
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
}));

const Subtitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightRegular,
  margin: '5px 0 0',

  [theme.breakpoints.up('sm')]: {
    marginTop: '8px',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '10px',
  },

  [theme.breakpoints.up('lg')]: {
    marginTop: '12px',
  },
}));

import { MewaAdvantagesIcon } from '@mewa/types';

import { ReactComponent as MewaAdvantagesCertificateIcon } from './mewa_icon_certificate.svg';
import { ReactComponent as MewaAdvantagesCostSavingIcon } from './mewa_icon_cost_saving.svg';
import { ReactComponent as MewaAdvantagesMbs1Icon } from './mewa_icon_mbs1.svg';
import { ReactComponent as MewaAdvantagesMbs2Icon } from './mewa_icon_mbs2.svg';
import { ReactComponent as MewaAdvantagesMbs3Icon } from './mewa_icon_mbs3.svg';
import { ReactComponent as MewaAdvantagesMbs4Icon } from './mewa_icon_mbs4.svg';
import { ReactComponent as MewaAdvantagesMbs5Icon } from './mewa_icon_mbs5.svg';
import { ReactComponent as MewaAdvantagesMbs6Icon } from './mewa_icon_mbs6.svg';
import { ReactComponent as MewaAdvantagesMbs7Icon } from './mewa_icon_mbs7.svg';
import { ReactComponent as MewaAdvantagesMbs8Icon } from './mewa_icon_mbs8.svg';
import { ReactComponent as MewaAdvantagesMbs9Icon } from './mewa_icon_mbs9.svg';
import { ReactComponent as MewaAdvantagesMbsComfortableIcon } from './mewa_icon_mbs_comfortable.svg';
import { ReactComponent as MewaAdvantagesMts1Icon } from './mewa_icon_mts1.svg';
import { ReactComponent as MewaAdvantagesMts2Icon } from './mewa_icon_mts2.svg';
import { ReactComponent as MewaAdvantagesMts3Icon } from './mewa_icon_mts3.svg';
import { ReactComponent as MewaAdvantagesMts4Icon } from './mewa_icon_mts4.svg';
import { ReactComponent as MewaAdvantagesMts5Icon } from './mewa_icon_mts5.svg';
import { ReactComponent as MewaAdvantagesMts6Icon } from './mewa_icon_mts6.svg';
import { ReactComponent as MewaAdvantagesMtsComfortableIcon } from './mewa_icon_mts_comfortable.svg';
import { ReactComponent as MewaAdvantagesSustainableIcon } from './mewa_icon_sustainable.svg';

export const mewaAdvantagesIconMap: Record<
  MewaAdvantagesIcon,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  [MewaAdvantagesIcon.MbsWideRange]: MewaAdvantagesMbs1Icon,
  [MewaAdvantagesIcon.MbsIndividualEquipment]: MewaAdvantagesMbs2Icon,
  [MewaAdvantagesIcon.MbsNoRiskOfConfusion]: MewaAdvantagesMbs3Icon,
  [MewaAdvantagesIcon.MbsInYourBrandDesign]: MewaAdvantagesMbs4Icon,
  [MewaAdvantagesIcon.MbsFlawlessHygiene]: MewaAdvantagesMbs5Icon,
  [MewaAdvantagesIcon.MbsCarefreePackage]: MewaAdvantagesMbs6Icon,
  [MewaAdvantagesIcon.MbsPureRelief]: MewaAdvantagesMbs7Icon,
  [MewaAdvantagesIcon.MbsSecureSupplyInYourArea]: MewaAdvantagesMbs8Icon,
  [MewaAdvantagesIcon.MbsSustainabilityInclusive]: MewaAdvantagesMbs9Icon,
  [MewaAdvantagesIcon.MbsComfortable]: MewaAdvantagesMbsComfortableIcon,
  [MewaAdvantagesIcon.MtsGreatVariety]: MewaAdvantagesMts1Icon,
  [MewaAdvantagesIcon.MtsQualityGuaranteed]: MewaAdvantagesMts2Icon,
  [MewaAdvantagesIcon.MtsPureRelief]: MewaAdvantagesMts3Icon,
  [MewaAdvantagesIcon.MtsSecureSupplyInYourArea]: MewaAdvantagesMts4Icon,
  [MewaAdvantagesIcon.MtsSustainabilityInclusive]: MewaAdvantagesMts5Icon,
  [MewaAdvantagesIcon.MtsCarefreePackage]: MewaAdvantagesMts6Icon,
  [MewaAdvantagesIcon.MtsComfortable]: MewaAdvantagesMtsComfortableIcon,
  [MewaAdvantagesIcon.CostSaving]: MewaAdvantagesCostSavingIcon,
  [MewaAdvantagesIcon.Sustainable]: MewaAdvantagesSustainableIcon,
  [MewaAdvantagesIcon.Certificate]: MewaAdvantagesCertificateIcon,
};

export {
  MewaAdvantagesMbs1Icon,
  MewaAdvantagesMbs2Icon,
  MewaAdvantagesMbs3Icon,
  MewaAdvantagesMbs4Icon,
  MewaAdvantagesMbs5Icon,
  MewaAdvantagesMbs6Icon,
  MewaAdvantagesMbs7Icon,
  MewaAdvantagesMbs8Icon,
  MewaAdvantagesMbs9Icon,
  MewaAdvantagesMbsComfortableIcon,
  MewaAdvantagesMts1Icon,
  MewaAdvantagesMts2Icon,
  MewaAdvantagesMts3Icon,
  MewaAdvantagesMts4Icon,
  MewaAdvantagesMts5Icon,
  MewaAdvantagesMts6Icon,
  MewaAdvantagesMtsComfortableIcon,
  MewaAdvantagesCostSavingIcon,
  MewaAdvantagesSustainableIcon,
  MewaAdvantagesCertificateIcon,
};

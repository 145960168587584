import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass, { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

import { Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import {
  SliderButtonNext,
  SliderButtonPrev,
  SliderButtonStyle,
} from '../slider-button/slider-button';
import Caption from '../text/caption';
import FadeText from '../text/fade-text';
import classes from './image-swiper.module.scss';

type SlideData = {
  description: string;
  imageCaptionTitle?: string | null;
  imageCaptionText?: string | null;
  imageAltText?: string | null;
  image: React.ReactElement;
};

type Props = {
  slides: SlideData[];
  variant?: 'default' | 'quote';
};

const navStyling: SliderButtonStyle = {
  fillMode: 'outlined',
  color: 'dark-coal',
};
const navHoverStyling: SliderButtonStyle = {
  fillMode: 'outlined',
  color: 'light-red',
};

export const ImageSwiper = ({ slides, variant }: Props) => {
  const [imageSwiper, setImageSwiper] = useState<SwiperClass | null>(null);
  const [imageCaptionSwiper, setImageCaptionSwiper] =
    useState<SwiperClass | null>(null);
  const [curIndex, setCurIndex] = useState(0);
  const [curSlideLabelWidth, setCurSlideLabelWidth] = useState(35);

  const onPrevSlide = useCallback(() => {
    if (imageSwiper) {
      imageSwiper.slidePrev();
    }
  }, [imageSwiper]);

  const onNextSlide = useCallback(() => {
    if (imageSwiper) {
      imageSwiper.slideNext();
    }
  }, [imageSwiper]);

  const onSlideChange = useCallback(
    (s: SwiperClass) => {
      setCurIndex(s.realIndex);
    },
    [setCurIndex],
  );

  const onSlideIndicatorSizeChange = useCallback(
    (width: number) => {
      setCurSlideLabelWidth(Math.round(width));
    },
    [setCurSlideLabelWidth],
  );

  // sync image slider index with text slider index
  useEffect(() => {
    imageCaptionSwiper?.slideTo(curIndex);
  }, [curIndex, imageCaptionSwiper]);

  const isQuote = variant === 'quote';
  const textFadeInDelay = isQuote ? 0 : 100;

  const CaptionTextComponent = isQuote ? QuoteCaptionText : DefaultCaptionText;

  return (
    <Container>
      <Grid item xs={12} md={isQuote ? 6 : 7}>
        <div>
          <Swiper
            className={classes.imageSwiper}
            modules={[EffectFade]}
            effect="fade"
            loop
            normalizeSlideIndex
            loopAdditionalSlides={2}
            allowSlideNext
            allowSlidePrev
            onSwiper={setImageSwiper}
            onSlideChange={onSlideChange}
          >
            {slides.map((slide, idx) => (
              <SwiperSlide key={idx}>{slide.image}</SwiperSlide>
            ))}
          </Swiper>

          <NavigationButtonsMobile>
            {slides.length > 1 && (
              <>
                <SliderButtonPrev
                  styling={navStyling}
                  hoverStyling={navHoverStyling}
                  onClick={onPrevSlide}
                  mobileSmall
                />
                <SliderButtonNext
                  styling={navStyling}
                  hoverStyling={navHoverStyling}
                  onClick={onNextSlide}
                  mobileSmall
                />
              </>
            )}
          </NavigationButtonsMobile>

          <Swiper
            modules={[EffectFade]}
            effect="fade"
            normalizeSlideIndex
            allowSlideNext
            allowSlidePrev
            onSwiper={setImageCaptionSwiper}
            allowTouchMove={false}
          >
            {slides.map((slide, idx) => (
              <SwiperSlide key={idx} className={classes.captionSlide}>
                {(slide.imageCaptionTitle || slide.imageCaptionText) && (
                  <ImageCaption variant="large">
                    {slide.imageCaptionTitle && (
                      <ImageCaptionTitle>
                        {slide.imageCaptionTitle} <br />
                      </ImageCaptionTitle>
                    )}
                    {slide.imageCaptionText && slide.imageCaptionText}
                  </ImageCaption>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Grid>
      <Grid item xs={1} sx={{ display: { xs: 'none', md: 'block' } }} />
      <Grid item xs={11} md={isQuote ? 4 : 3} lg={isQuote ? 4 : 2}>
        <NavigationButtons>
          {slides.length > 1 && (
            <>
              <SliderButtonPrev
                styling={navStyling}
                hoverStyling={navHoverStyling}
                onClick={onPrevSlide}
              />
              <SliderButtonNext
                styling={navStyling}
                hoverStyling={navHoverStyling}
                onClick={onNextSlide}
              />
            </>
          )}
        </NavigationButtons>

        {!isQuote && (
          <SlideIndicator>
            <ActiveIndex
              fadeInDelayMs={textFadeInDelay}
              onLabelSizeChange={onSlideIndicatorSizeChange}
              text={(curIndex + 1).toString().padStart(2, '0')}
              component="span"
            />{' '}
            <TotalSlideCount
              style={{ transform: `translateX(${curSlideLabelWidth + 5}px)` }}
            >
              / {slides.length.toString().padStart(2, '0')}
            </TotalSlideCount>
          </SlideIndicator>
        )}
        <CaptionContainer>
          <CaptionTextComponent
            text={slides[curIndex].description}
            fadeInDelayMs={textFadeInDelay}
          />
        </CaptionContainer>
      </Grid>
    </Container>
  );
};

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '50px',
  marginBottom: '50px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '80px',
    marginBottom: '80px',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '180px',
    marginBottom: '150px',
  },
}));

const NavigationButtons = styled('div')(({ theme }) => ({
  display: 'flex',

  button: {
    marginRight: '14px',
  },

  marginBottom: '50px',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavigationButtonsMobile = styled('div')(({ theme }) => ({
  display: 'flex',

  button: {
    marginRight: '14px',
  },

  marginTop: '20px',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const SlideIndicator = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '80px',
  marginBottom: '28px',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '25px',
    marginBottom: '8px',
  },
}));

const CaptionContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: '66.67%',
  },

  [theme.breakpoints.up('md')]: {
    width: '100%',
  },
}));

const DefaultCaptionText = styled(FadeText)(({ theme }) => ({
  ...theme.typography.copy,
  margin: 0,
}));

const QuoteCaptionText = styled(FadeText)(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,

  [theme.breakpoints.down('lg')]: {
    marginTop: '34px',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '34px',
  },
}));

const ActiveIndex = styled(FadeText)(({ theme }) => ({
  ...theme.typography.h4,
}));

const TotalSlideCount = styled('span')(({ theme }) => ({
  position: 'absolute',
  bottom: '5px',
  left: 0,
  transition: 'transform var(--transition-duration) var(--transition-timing)',

  [theme.breakpoints.down('sm')]: {
    bottom: '2px',
  },
}));

const ImageCaption = styled(Caption)(({ theme }) => ({
  marginTop: '36px',
  width: '50%',

  [theme.breakpoints.down('lg')]: {
    width: '60%',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
    width: '90%',
  },
}));

const ImageCaptionTitle = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

import React, { useState } from 'react';

import { Grid } from '@mui/material';

import { useVisibilityAnimation, useWindowResize } from '@mewa/ui';

import { LinkData } from '../types';
import { VISIBILITY_ANIMATION_REMOVE_DELAY } from './animation-constants';
import { LinkList } from './link-columns';
import MenuWrapper from './menu-wrapper';

type Props = {
  langPrefix: string;
  show: boolean;
  columnData: MenuColumnData;
  targetElement: HTMLDivElement;
  onMouseEnter: () => void;
};

const CompanyMenu = ({
  langPrefix,
  show,
  columnData,
  targetElement,
  onMouseEnter,
}: Props) => {
  const [renderUI, showUI] = useVisibilityAnimation(
    show,
    VISIBILITY_ANIMATION_REMOVE_DELAY,
  );

  return (
    <MenuWrapper
      renderUI={renderUI}
      showUI={showUI}
      show={show}
      onMouseEnter={onMouseEnter}
    >
      <MenuContent
        langPrefix={langPrefix}
        columnData={columnData}
        targetElement={targetElement}
      />
    </MenuWrapper>
  );
};

const MenuContent = ({
  langPrefix,
  columnData,
  targetElement,
}: {
  langPrefix: string;
  columnData: MenuColumnData;
  targetElement: HTMLDivElement;
}) => {
  const [targetElementPosX, setPosition] = useState(
    targetElement.getBoundingClientRect().x,
  );

  useWindowResize(() => {
    setPosition(targetElement.getBoundingClientRect().x);
  });

  return (
    <Grid item xs={12}>
      <LinkList
        langPrefix={langPrefix}
        links={columnData.links}
        sx={{
          position: 'relative',
          marginLeft: 'calc(var(--margin)*-1)',
          transform: `translateX(${targetElementPosX}px)`,
          display: 'inline-block',
          marginTop: 0,
        }}
      />
    </Grid>
  );
};

type MenuColumnData = {
  title?: string;
  links: LinkData[];
};

export default CompanyMenu;

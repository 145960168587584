import React, { ChangeEvent, useCallback } from 'react';

import { Grid, styled } from '@mui/material';

import { ResponsiveGrid, SelectArrowDownIcon } from '@mewa/ui';

import t from '../../../../libs/translate';
import * as stageExperiment from '../../../a-b-testing/stage-experiment';
import {
  HeaderAndFooterTranslations,
  SearchPageTranslations,
  ServiceSegmentLink,
} from '../../header/types';
import { PageHeadlineWrapper } from '../../page-headline-wrapper';
import { trackAndNavigate } from '../helper';
import { useQuickNavigationState } from '../state';
import type { IndustryHierarchy } from '../types';
import SubmitButton from './submit-button';

const MobileQuickNavigation = ({
  industries,
  serviceSegments,
  langPrefix,
  translations,
  nonClothingSlug,
  productOverviewSlug,
}: {
  industries: IndustryHierarchy[];
  serviceSegments: ServiceSegmentLink[];
  langPrefix: string;
  translations: HeaderAndFooterTranslations & SearchPageTranslations;
  nonClothingSlug: string;
  productOverviewSlug: string;
}) => {
  const state = useQuickNavigationState(
    industries,
    serviceSegments,
    t(translations, 'quicknav_all_industries'),
    t(translations, 'quicknav_all_products'),
  );

  const onIndustrySelectionChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const val = evt.target.value;
      let idx = state.industryOptions.findIndex((opt) => opt.name === val);
      idx = idx >= 0 ? idx : 0;

      state.selectIndustry(idx);
    },
    [state],
  );

  const onProductsSelectionChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const val = evt.target.value;
      const idx =
        state.serviceSegmentOptions.findIndex((opt) => opt.name === val) || 0;
      state.selectServiceSegment(idx);
    },
    [state],
  );

  const onSearch = useCallback(() => {
    stageExperiment.sendEvent(true);
    trackAndNavigate(
      `${langPrefix}/${state.link(productOverviewSlug, nonClothingSlug)}`,
    );
  }, [langPrefix, state, productOverviewSlug, nonClothingSlug]);

  const industryLabel = state.industryLabel(
    t(translations, 'quicknav_industry_placeholder'),
  );
  const serviceSegmentLabel = state.serviceSegmentLabel(
    t(translations, 'quicknav_products_placeholder'),
  );

  return (
    <ResponsiveGrid>
      <Grid item xs={12} sm={8} component="article">
        <HeaderWrapper>
          <PageHeadlineWrapper
            title={t(translations, 'homepage_title')}
            subtitle={t(translations, 'homepage_subtitle')}
          />
        </HeaderWrapper>

        <SelectContainer>
          <div>
            <SelectLabel>{t(translations, 'general_industry')}</SelectLabel>
            <SelectValue>{industryLabel}</SelectValue>
          </div>

          <SelectIcon>
            <SelectArrowDownIcon />
          </SelectIcon>

          <NativeSelectOverlay onChange={onIndustrySelectionChange}>
            {state.industryOptions.map((opt) => (
              <option key={opt.name} value={opt.name}>
                {opt.name}
              </option>
            ))}
          </NativeSelectOverlay>
        </SelectContainer>

        <SelectContainer>
          <div>
            <SelectLabel>{t(translations, 'general_products')}</SelectLabel>
            <SelectValue>{serviceSegmentLabel}</SelectValue>
          </div>

          <SelectIcon>
            <SelectArrowDownIcon />
          </SelectIcon>

          <NativeSelectOverlay
            onChange={onProductsSelectionChange}
            value={
              state.selectedServiceSegment !== null
                ? state.serviceSegmentOptions[state.selectedServiceSegment].name
                : state.serviceSegmentOptions[0].name
            }
          >
            {state.serviceSegmentOptions.map((opt) => (
              <option key={opt.name} value={opt.name}>
                {opt.name}
              </option>
            ))}
          </NativeSelectOverlay>
        </SelectContainer>

        <SubmitButton
          onClick={onSearch}
          style={{ height: '72px', paddingLeft: '20px' }}
        >
          {t(translations, 'general_search')}
        </SubmitButton>
      </Grid>
    </ResponsiveGrid>
  );
};

export default MobileQuickNavigation;

const HeaderWrapper = styled('div')({
  marginBottom: '32px',
});

const SelectContainer = styled('div')({
  border: '1px solid var(--color-medium-grey)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  padding: '16px 24px 17px 19px',
  marginBottom: '10px',
});

const NativeSelectOverlay = styled('select')({
  WebkitAppearance: 'none', // required for safari to modify height
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  border: 0,
});

const SelectLabel = styled('div')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
  opacity: 0.5,
  flexGrow: 1,
  width: '100%',
}));

const SelectValue = styled('div')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
}));

const SelectIcon = styled('span')({
  flexGrow: 0,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  svg: {
    color: 'var(--color-light-red)',
  },
});

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { styled, SxProps } from '@mui/material';

import { useDebouncedWindowResize } from '../../hooks/window-resize';
import cssVars from '../../styles/export-variables.module.scss';
import { ResponsiveImageProps } from '../types';
import { IconButton, Video, VideoContainer } from './video-base';

type Props = {
  posterImage: React.ReactElement<ResponsiveImageProps> | null;
  desktopSources: {
    src: string;
    type: string;
  }[];
  mobileSources?: {
    src: string;
    type: string;
  }[];
  sx?: SxProps;
};

const MOBILE_BREAKPOINT = parseInt(cssVars.maxWidthVp3);

export const VideoWithControls = ({
  posterImage,
  desktopSources,
  mobileSources,
  sx,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setMobile] = useState(
    ((typeof window !== 'undefined' && window.innerWidth) ||
      MOBILE_BREAKPOINT) < MOBILE_BREAKPOINT,
  );

  const onStartVideo = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  const onResize = useCallback(() => {
    setMobile(window.innerWidth < MOBILE_BREAKPOINT);
  }, [setMobile]);

  useDebouncedWindowResize(onResize, 200);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoRef, isPlaying]);

  const sources = isMobile && mobileSources ? mobileSources : desktopSources;

  return (
    <VideoContainer sx={sx}>
      {(isPlaying || !posterImage) && (
        <Video ref={videoRef} playsInline controls>
          {sources.map((s) => (
            <source key={s.src} src={s.src} type={s.type} />
          ))}
        </Video>
      )}
      {!isPlaying && (
        <>
          <PosterImage>{posterImage}</PosterImage>
          <>
            <PlayButton
              onClick={onStartVideo}
              className="play-icon-desktop"
              variant="text"
              startIcon={<PlayIconDesktop />}
            />
            <PlayButton
              onClick={onStartVideo}
              className="play-icon-mobile"
              variant="text"
              startIcon={<PlayIconMobile />}
            />
          </>
        </>
      )}
    </VideoContainer>
  );
};

export default VideoWithControls;

const PosterImage = styled('div')({
  position: 'absolute',
  width: '100%',
});

const PlayButton = styled(IconButton)({
  svg: {
    transition:
      'transform var(--transition-duration), color var(--transition-duration)',
  },

  ':hover svg': {
    transform: 'scale(1.05)',
    color: 'var(--color-light-red)',
  },
});

const PlayIconDesktop = () => (
  <svg
    viewBox="0 0 112 112"
    fill="none"
    width="98"
    height="98"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {`
      svg { color: #222222; }

      .triangle {
        fill: currentColor;
      }`}
    </style>
    <rect
      x="1.18994"
      y="1.30249"
      width="109.737"
      height="109.628"
      rx="54.814"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M67.9654 55.2503C68.6326 55.6351 68.6326 56.598 67.9654 56.9828L50.8542 66.8523C50.1875 67.2368 49.3546 66.7557 49.3546 65.9861L49.3546 46.247C49.3546 45.4774 50.1875 44.9963 50.8542 45.3808L67.9654 55.2503Z"
      className="triangle"
    />
  </svg>
);

const PlayIconMobile = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <style>
      {`
      svg { color: #222222; }

      .triangle {
        fill: currentColor;
      }`}
    </style>
    <rect
      x="1"
      y="1"
      width="58"
      height="58"
      rx="29"
      fill="white"
      stroke="white"
      strokeWidth="2"
    />
    <path
      d="M35.7013 29.1348C36.3674 29.5198 36.3674 30.4814 35.7013 30.8664L27.9059 35.372C27.2392 35.7573 26.4055 35.2762 26.4055 34.5062L26.4055 25.495C26.4055 24.725 27.2392 24.2439 27.9059 24.6292L35.7013 29.1348Z"
      className="triangle"
    />
  </svg>
);

import React, { PropsWithChildren } from 'react';

import { Divider, Grid, styled } from '@mui/material';

import { MarkdownContainer } from '../markdown/markdown-container';

type Props = {
  className?: string;
  fullWidth?: boolean;
};

export const RightGridColumn = React.memo(
  ({
    children,
    className = '',
    fullWidth = false,
  }: PropsWithChildren<Props>) => (
    <Grid container columns={{ xs: 12, sm: 12, md: 7 }} className={className}>
      <Grid item xs={12} md={1} />
      <Grid item xs={fullWidth ? 12 : 11} sm={fullWidth ? 12 : 8} md={6}>
        {children}
      </Grid>
    </Grid>
  ),
);

export const TextContent = styled(MarkdownContainer, {
  shouldForwardProp: (prop) => prop !== 'noTopElementSpacing',
})<{ noTopElementSpacing: boolean }>(({ noTopElementSpacing }) => ({
  marginBottom: '0 !important',

  '*:first-of-type': {
    marginTop: noTopElementSpacing ? 0 : undefined,
  },

  'p:first-child': {
    marginTop: 0,
  },

  'p:last-child': {
    marginBottom: 0,
  },
}));

export const ResponsiveDivider = styled(Divider)(({ theme }) => ({
  display: 'none',

  marginTop: '53px',
  marginBottom: 0,

  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '80px',
  },
}));

export const ClaimWithContentDivider = () => (
  <RightGridColumn>
    <ResponsiveDivider />
  </RightGridColumn>
);

import { styled, SxProps, Theme } from '@mui/material';

export const MarkdownContainer = ({
  html,
  sx,
  className,
}: {
  html: string;
  sx?: SxProps;
  className?: string;
}) => (
  <MarkdownContainerStyled
    className={className}
    sx={sx}
    dangerouslySetInnerHTML={{
      __html: html,
    }}
  />
);

export const markdownH1Style = (theme: Theme) => ({
  ...theme.typography.h1,
  marginTop: '176px',
  marginBottom: '60px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
  },
});

export const markdownTextLinkStyle = {
  color: 'var(--color-light-red)',
  textDecoration: 'underline',
  textUnderlineOffset: '5px',
  textDecorationColor: 'transparent',
  transition:
    'color var(--transition-duration), text-decoration-color var(--transition-duration)',

  ':hover': {
    color: 'var(--color-light-red)',
    textDecorationColor: 'var(--color-light-red)',
  },
};

export const markdownH2Style = (theme: Theme) => ({
  ...theme.typography.h2,
  marginTop: '60px',
  marginBottom: 0,

  [theme.breakpoints.down('sm')]: {
    marginTop: '30px',
  },
});

const MarkdownContainerStyled = styled('div')(({ theme }) => ({
  marginBottom: '150px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '200px',
  },

  h1: markdownH1Style(theme),
  h2: markdownH2Style(theme),
  h3: {
    ...theme.typography.h3,
    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  h4: {
    ...theme.typography.h4,
    marginTop: '60px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  h5: {
    ...theme.typography.h5,
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  h6: {
    ...theme.typography.h6,
    marginTop: '30px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
  },
  p: {
    ...theme.typography.copy,
  },

  'p, li': {
    ...theme.typography.copy,

    a: markdownTextLinkStyle,
  },

  ul: {
    marginLeft: '15px',
    paddingLeft: 0,

    li: {
      margin: '12px 0',
    },
  },

  table: {
    display: 'block',
    overflowX: 'auto',
    borderCollapse: 'collapse',
    marginTop: '30px',
    marginRight: 'calc(-1 * (100vw - var(--gutter) - 100%))',
    marginBottom: '30px',

    '& thead tr': {
      borderTop: '1px solid var(--color-dark-coal)',
      borderBottom: '1px solid var(--color-dark-coal)',
    },

    '& tbody tr': {
      borderTop: '1px solid var(--color-medium-light-grey)',
      borderBottom: '1px solid var(--color-medium-light-grey)',
    },

    '& th, td': {
      ...theme.typography.copy,
      padding: '19px 70px 21px 20px',
      textAlign: 'left',

      [theme.breakpoints.up('sm')]: {
        paddingLeft: '10px',
      },
    },

    [theme.breakpoints.up('sm')]: {
      display: 'table',
      tableLayout: 'fixed',
      width: '100%',
      marginBottom: '60px',
    },
  },

  'h1, h2, h3, p': {
    whiteSpace: 'pre-line',
  },
}));

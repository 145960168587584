import React, { useCallback, useState } from 'react';

import { Grid, styled } from '@mui/material';

import { useKeyPress } from '../hooks/keyboard';
import ResponsiveGrid from './grid';

type Props = {
  color?: string;
};

export const DebugGrid = ({ color }: Props) => {
  const items = [];

  for (let i = 0; i < 12; i++) {
    items.push(
      <Grid key={i} item xs={1}>
        <DebugGridItem sx={{ backgroundColor: color }} />
      </Grid>,
    );
  }

  return (
    <Container>
      <ResponsiveGrid
        sx={{
          height: '100%',
        }}
        gridProps={{
          sx: {
            height: '100%',
          },
        }}
      >
        {items}
      </ResponsiveGrid>
    </Container>
  );
};

export default DebugGrid;

const Container = styled('div')({
  zIndex: 1,
  position: 'fixed',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  pointerEvents: 'none',
});

const DebugGridItem = styled('div')({
  backgroundColor: 'rgba(255, 80, 160, 0.18)',
  height: '100%',
});

export const KeyboardControlledDebugGrid = ({
  children,
}: React.PropsWithChildren) => {
  const [showGrid, setShowGrid] = useState(false);

  const toggleGrid = useCallback(() => {
    setShowGrid(!showGrid);
  }, [showGrid, setShowGrid]);

  useKeyPress(toggleGrid, 'KeyG', true);

  return (
    <>
      {children}
      {showGrid && <DebugGrid />}
    </>
  );
};

import { useMemo, useState } from 'react';

import { ServiceSegmentLink } from '../header/types';
import type { IndustryHierarchy } from './types';

export type IndustryOptions = ReturnType<
  typeof useQuickNavigationState
>['industryOptions'];

export type ServiceSegmentOptions = ReturnType<
  typeof useQuickNavigationState
>['serviceSegmentOptions'];

export const useQuickNavigationState = (
  industries: IndustryHierarchy[],
  serviceSegments: ServiceSegmentLink[],
  allIndustriesLabel: string,
  allProductsLabel: string,
) => {
  // Not all industries have products in all service segments. Here we store a
  // list of service segments which should be displayed in the "product"
  // dropdown based on the currently selected industry.
  const [relevantServiceSegments, setRelevantServiceSegments] =
    useState<ServiceSegmentLink[]>(serviceSegments);

  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(null);
  const [selectedServiceSegment, setSelectedServiceSegment] = useState<
    number | null
  >(null);

  const industryOptions = useMemo(
    () => [{ name: allIndustriesLabel }, ...industries],
    [industries, allIndustriesLabel],
  );

  const serviceSegmentOptions = useMemo(
    () => [{ name: allProductsLabel }, ...relevantServiceSegments],
    [relevantServiceSegments, allProductsLabel],
  );

  /**
   * Select an industry and automatically set the available service segment
   * options based on the selected industry.
   */
  const selectIndustry = (idx: number) => {
    idx = idx || 0;

    setSelectedIndustry(idx);
    setSelectedServiceSegment(null);

    if (idx === 0) {
      // all industries
      setRelevantServiceSegments(serviceSegments);
    } else {
      // filter based on industry selection
      setRelevantServiceSegments(industries[idx - 1].serviceSegments);
    }
  };

  const selectServiceSegment = (idx: number) => {
    setSelectedServiceSegment(idx);
  };

  /**
   * Create a link with a given lang prefix for the current state.
   */
  const link = (
    productOverviewSlug: string,
    nonClothingSlug: string,
  ): string => {
    const serviceSegmentIdx = selectedServiceSegment || 0;
    const industriesIdx = selectedIndustry || 0;
    const industry = industries[industriesIdx - 1];
    let serviceSegment = relevantServiceSegments[serviceSegmentIdx - 1];

    if (serviceSegmentIdx === 0 && industriesIdx === 0) {
      return productOverviewSlug;
    } else if (serviceSegmentIdx === 0) {
      // All products for a specific industry
      serviceSegment = industry.serviceSegments[0];
    } else if (industriesIdx === 0) {
      // Service segment for a specific industry
      const serviceSegment = relevantServiceSegments[serviceSegmentIdx - 1];
      return serviceSegment.slug;
    }
    if (serviceSegment.category === 'MBS') {
      return `${industry.slug}-${serviceSegment.slug}`;
    }

    return `${industry.slug}-${nonClothingSlug}`;
  };

  /**
   * Get the label for the selected industry.
   */
  const industryLabel = (fallback: string) =>
    selectedIndustry !== null
      ? industryOptions[selectedIndustry].name
      : fallback;

  /**
   * Get the label for the selected service segment.
   */
  const serviceSegmentLabel = (fallback: string) =>
    selectedServiceSegment !== null
      ? serviceSegmentOptions[selectedServiceSegment].name
      : fallback;

  return {
    selectedIndustry,
    selectedServiceSegment,
    serviceSegments: relevantServiceSegments,
    industryOptions,
    serviceSegmentOptions,

    selectIndustry,
    selectServiceSegment,
    link,
    industryLabel,
    serviceSegmentLabel,
  };
};

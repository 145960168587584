import { useRef, useState } from 'react';

import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  accordionDetailsClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Grid,
  paperClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { useTabGlider } from './use-tab-glider';

export const Tabs = ({
  sections,
}: {
  sections: {
    title: string;
    content: React.ReactElement;
  }[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const tabsContainer = useRef<HTMLDivElement>(null);
  useTabGlider(tabsContainer, activeIndex);

  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  return isMobile ? (
    sections.map((section, idx) => (
      <StyledAccordion
        key={idx}
        expanded={idx === activeIndex}
        onChange={() => setActiveIndex(idx)}
      >
        <AccordionSummary>{section.title}</AccordionSummary>
        <StyledAccordionDetails>{section.content}</StyledAccordionDetails>
      </StyledAccordion>
    ))
  ) : (
    <>
      <ResponsiveGrid>
        <Grid item xs={12}>
          <TabTitles ref={tabsContainer} role="tablist">
            {sections.map((section, idx) => (
              <TabTitle
                key={idx}
                onClick={() => setActiveIndex(idx)}
                isActive={idx === activeIndex}
                id={`tab-${idx}`}
                role="tab"
                aria-controls={`tabPanel-${idx}`}
                aria-selected={idx === activeIndex}
              >
                {section.title}
              </TabTitle>
            ))}
          </TabTitles>
        </Grid>
      </ResponsiveGrid>
      {sections.map((section, idx) => (
        <Section
          key={idx}
          isActive={idx === activeIndex}
          id={`tabPanel-${idx}`}
          role="tabpanel"
          aria-labelledby={`tab-${idx}`}
        >
          {section.content}
        </Section>
      ))}
    </>
  );
};

const TabTitles = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '40px',
  justifyContent: 'center',
  alignItems: 'flex-end',
  position: 'relative',

  '&:after': {
    content: "''",
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '5px',
    scale: 'var(--_width, 0) 1',
    translate: 'var(--_left, 0) 0',
    transformOrigin: 'left',
    transition: 'scale 200ms, translate var(--transition-duration)',
    backgroundColor: 'var(--color-light-red)',
    display: 'var(--_show, none)',
  },
}));

const TabTitle = styled('button')<{ isActive: boolean }>(
  ({ isActive, theme }) => ({
    ...(isActive ? theme.typography.h4 : theme.typography.h6),
    backgroundColor: 'transparent',
    border: 'none',
    color: isActive ? 'var(--color-light-red)' : undefined,
    textWrap: 'nowrap',
    padding: '0 0 15px',
    transition: 'color var(--transition-duration)',

    '&:hover': {
      color: 'var(--color-light-red)',
      cursor: 'pointer',
    },
  }),
);

const Section = styled('section')<{ isActive: boolean }>(
  ({ theme, isActive }) => ({
    display: isActive ? undefined : 'none',

    [theme.breakpoints.up('sm')]: {
      paddingTop: '35px',
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
    },

    [theme.breakpoints.up('lg')]: {
      paddingTop: '60px',
    },
  }),
);

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${paperClasses.root}.${accordionClasses.root}:first-of-type > .${accordionSummaryClasses.root}`]:
    {
      borderTop: 'none',
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)({
  [`&.${accordionDetailsClasses.root}`]: {
    paddingBottom: '44px',
  },
});

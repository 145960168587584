import { DataLayer } from '@piwikpro/react-piwik-pro';
import { useCallback } from 'react';

import { navigate } from 'gatsby';

import { ServiceSegmentType } from '@mewa/types';
import { ContactTeaserLabelProps } from '@mewa/ui';

import t from '../../../libs/translate';
import {
  ContactTeaserLabels,
  NavigationLinks,
} from '../../components/header/types';
import {
  clearServiceSegment,
  storeServiceSegment,
} from '../../templates/conversion/persistence';

export const useContactTeaser = ({
  serviceSegment,
  navigationLinks,
  untranslatedContactTeaserLabels,
  langPrefix,
}: {
  serviceSegment?: ServiceSegmentType;
  navigationLinks: NavigationLinks;
  untranslatedContactTeaserLabels: ContactTeaserLabels;
  langPrefix: string;
}) => {
  const tContactTeaser = (key: keyof ContactTeaserLabels) =>
    t(untranslatedContactTeaserLabels, key);

  const contactTeaserLabels: Record<keyof ContactTeaserLabelProps, string> = {
    consultationTitle: tContactTeaser('contact_teaser_consultation_title'),
    consultationSubTitle: tContactTeaser(
      'contact_teaser_consultation_description',
    ),
    consultationCTALabel: tContactTeaser(
      'contact_teaser_consultation_cta_label',
    ),
    contactTitle: tContactTeaser('contact_teaser_phone_headline'),
    contactSubTitle: tContactTeaser('contact_teaser_phone_description'),
    openingHours: tContactTeaser('contact_teaser_phone_opening_hours'),
    phoneNumber: tContactTeaser('general_contact_phone_number'),
  };

  const navigateToConversion = useCallback(() => {
    if (serviceSegment !== undefined) {
      storeServiceSegment(serviceSegment);
    } else {
      clearServiceSegment();
    }

    DataLayer.push({
      event: 'cta_contact_teaser',
    });

    navigate(`${langPrefix}/${navigationLinks.conversion.contact.slug}`);
  }, [navigationLinks, langPrefix, serviceSegment]);

  const onClickHotline = () => {
    DataLayer.push({ event: 'cta_contact_teaser_hotline' });
  };

  return { navigateToConversion, onClickHotline, contactTeaserLabels };
};

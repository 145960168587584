import { MewaCircleIcon } from '@mewa/types';

import { ReactComponent as DropletIcon } from './droplet.svg';
import { ReactComponent as MagnifierIcon } from './magnifier.svg';
import { ReactComponent as MbkIcon } from './mbk.svg';
import { ReactComponent as MbsMtsCombinedIcon } from './mbs-mts-combined.svg';
import { ReactComponent as MbsIcon } from './mbs.svg';
import { ReactComponent as MfsIcon } from './mfs.svg';
import { ReactComponent as MtrChangeLiquidIcon } from './mtr-change-liquid.svg';
import { ReactComponent as MtrCleaningIcon } from './mtr-cleaning.svg';
import { ReactComponent as MtrExchangeIcon } from './mtr-exchange.svg';
import { ReactComponent as MtrImmediateHelpIcon } from './mtr-immediate-help.svg';
import { ReactComponent as MtrIcon } from './mtr.svg';
import { ReactComponent as MtsIcon } from './mts.svg';
import { ReactComponent as MultitexIcon } from './multitex.svg';
import { ReactComponent as MwsIcon } from './mws.svg';
import { ReactComponent as ToptexIcon } from './toptex.svg';
import { ReactComponent as TruckRightIcon } from './truck-right.svg';
import { ReactComponent as WipingmopsIcon } from './wipingmops.svg';

export const mewaCircleIconMap: Record<
  MewaCircleIcon,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >
> = {
  [MewaCircleIcon.CategoryMbsMtsCombined]: MbsMtsCombinedIcon,
  [MewaCircleIcon.CategoryMbs]: MbsIcon,
  [MewaCircleIcon.CategoryMts]: MtsIcon,
  [MewaCircleIcon.CategoryMbk]: MbkIcon,
  [MewaCircleIcon.CategoryMfs]: MfsIcon,
  [MewaCircleIcon.CategoryMtr]: MtrIcon,
  [MewaCircleIcon.CategoryMultitex]: MultitexIcon,
  [MewaCircleIcon.CategoryMws]: MwsIcon,
  [MewaCircleIcon.CategoryToptex]: ToptexIcon,
  [MewaCircleIcon.CategoryWipingmops]: WipingmopsIcon,
  [MewaCircleIcon.Truck]: TruckRightIcon,
  [MewaCircleIcon.Magnifier]: MagnifierIcon,
  [MewaCircleIcon.Droplet]: DropletIcon,
  [MewaCircleIcon.MtrCleaning]: MtrCleaningIcon,
  [MewaCircleIcon.MtrChangeLiquid]: MtrChangeLiquidIcon,
  [MewaCircleIcon.MtrExchange]: MtrExchangeIcon,
  [MewaCircleIcon.MtrImmediateHelp]: MtrImmediateHelpIcon,
};

export { ReactComponent as MewaCircleBackground } from './cycle.svg';

export {
  MbsMtsCombinedIcon,
  MbsIcon,
  MtsIcon,
  MbkIcon,
  MfsIcon,
  MtrIcon,
  MultitexIcon,
  MwsIcon,
  ToptexIcon,
  WipingmopsIcon,
  TruckRightIcon,
  MagnifierIcon,
  DropletIcon,
  MtrCleaningIcon,
  MtrChangeLiquidIcon,
  MtrExchangeIcon,
  MtrImmediateHelpIcon,
};

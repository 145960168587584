import React, { MouseEvent, useCallback, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperClass, { EffectFade, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

import {
  Button,
  buttonClasses,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { MewaCircleIcon, MewaCircleType } from '@mewa/types';

import { MewaCircleBackground } from '../../../assets/icons';
import { responsiveSwiperBreakpoints } from '../card-slider/core/constants';
import LabeledIcon from '../icon-grid/labeled-icon';
import { MobileSlider } from '../mobile-slider/mobile-slider';
import {
  SliderButtonNext,
  SliderButtonPrev,
  SliderButtonStyle,
} from '../slider-button/slider-button';
import { LinkProps } from '../types';
import { iconMap } from './icon-mapping';
import styles from './mewa-circle.module.scss';

export type MewaCircleStep = {
  headline: string;
  description: string;
  icon: MewaCircleIcon;
};

export type MewaCircleData = {
  circleVariant: MewaCircleType;
  firstStep: MewaCircleStep;
  secondStep: MewaCircleStep;
  thirdStep: MewaCircleStep;
  fourthStep: MewaCircleStep;
  fifthStep: MewaCircleStep;
  locale: string;
};

export type MewaCircleProps = {
  circle: MewaCircleData;
  title?: string;
  link?: React.ReactElement<LinkProps>;
  // For Styled-Components
  className?: string;
  showResponsiveButtons?: boolean;
  optimizedForTabbedSlots?: boolean;
};

type MousePosition = {
  x: number;
  y: number;
};

const navButtonStyling = {
  styling: {
    fillMode: 'outlined',
    color: 'dark-coal',
  } as SliderButtonStyle,
  hoverStyling: {
    fillMode: 'outlined',
    color: 'light-red',
  } as SliderButtonStyle,
};

export const MewaCircle: React.FC<MewaCircleProps> = ({
  circle,
  title,
  link,
  className,
  showResponsiveButtons = false,
  optimizedForTabbedSlots = false,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [imageCaptions, setImageCaptions] = useState<SwiperClass | null>(null);
  const iconCycleRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const steps = [
    circle.firstStep,
    circle.secondStep,
    circle.thirdStep,
    circle.fourthStep,
    circle.fifthStep,
  ];

  /**
   * @returns the index of the button that exists at the given mouse position, or -1 if there is no button
   * at that position (or icon cycle element doesn't exist)
   */
  const getButtonIndexForMousePosition = useCallback(
    (mousePosition: MousePosition) => {
      if (iconCycleRef.current) {
        const buttons = Array.from(
          iconCycleRef.current.getElementsByTagName('button'),
        );
        const buttonDimensions = buttons?.map((button) =>
          button.getBoundingClientRect(),
        );

        const buttonIndex = buttonDimensions.findIndex((dim) => {
          // check whether the user clicked into the bounding box of the button
          const wasClicked =
            dim.left < mousePosition.x &&
            dim.right > mousePosition.x &&
            dim.top < mousePosition.y &&
            dim.bottom > mousePosition.y;
          return wasClicked;
        });

        return buttonIndex;
      }
      return -1;
    },
    [],
  );

  // On mobile the buttons mouse interactions are disabled to increase the
  // swipe area. This callback will handle click events to still support
  // navigation via a tap on the button.
  const stepClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      const clickedButtonIndex = getButtonIndexForMousePosition({
        x: e.clientX,
        y: e.clientY,
      });

      if (clickedButtonIndex >= 0) {
        // can be -1 if no button was clicked
        imageCaptions?.slideTo(clickedButtonIndex);
        setActiveStep(clickedButtonIndex);
      }
    },
    [getButtonIndexForMousePosition, imageCaptions],
  );

  const navigate = (offset: number) => () => {
    setActiveStep((step) => {
      const newPosition = (step + steps.length + offset) % steps.length;
      imageCaptions?.slideTo(newPosition);
      return newPosition;
    });
  };

  return (
    <Container className={className}>
      {isMobile ? (
        <>
          {title && <Headline>{title}</Headline>}
          <MobileSlider>
            {steps.map((step, idx) => (
              <StyledLabeledIcon
                key={idx}
                Icon={iconMap[step.icon]}
                title={step.headline}
                subtitle={step.description}
              />
            ))}
          </MobileSlider>
        </>
      ) : (
        <div className={styles.mewaCircle}>
          {title && <Headline>{title}</Headline>}

          <SliderButtonPrev
            className={`${styles.nav} ${styles.previous}`}
            onClick={navigate(-1)}
            mobileSmall={showResponsiveButtons}
            {...navButtonStyling}
          />
          <div
            className={`${styles.diagram} ${
              optimizedForTabbedSlots ? styles.tabbedSlotsOptimized : ''
            }`}
            onClick={stepClickHandler}
          >
            <MewaCircleBackground className={styles.cycleBackground} />
            <div className={styles.iconCycle} ref={iconCycleRef}>
              {steps.map((s, i) => {
                const Icon = iconMap[s.icon];

                return (
                  <button
                    key={i}
                    className={`${styles.iconContainer} ${
                      activeStep === i ? styles.active : ''
                    }`}
                    onClick={() => {
                      imageCaptions?.slideTo(i);
                      setActiveStep(i);
                    }}
                  >
                    <Icon />
                  </button>
                );
              })}
            </div>

            <Swiper
              className={styles.textSwiper}
              onAfterInit={(swiper: SwiperClass): void => {
                swiper.slideTo(activeStep);
              }}
              onSwiper={setImageCaptions}
              onSlideChange={(swiper) => {
                setActiveStep(swiper.realIndex);
              }}
              modules={[EffectFade, Pagination]}
              effect="fade"
              loop={true}
              normalizeSlideIndex={true}
              loopAdditionalSlides={5}
              allowSlideNext={true}
              allowSlidePrev={true}
              slideToClickedSlide={false}
              allowTouchMove={true}
              pagination={{
                type: 'bullets',
                dynamicBullets: true,
                dynamicMainBullets: 1,
                clickable: true,
                renderBullet: function (_index, className) {
                  return `<div class="${styles.navigationDot} ${className}"></div>`;
                },
              }}
              breakpoints={responsiveSwiperBreakpoints()}
            >
              {steps.map((s, i) => (
                <SwiperSlide key={i} className={`${styles.textSlide}`}>
                  <StepTitle>{s.headline}</StepTitle>
                  <StepText>{s.description}</StepText>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <SliderButtonNext
            className={`${styles.nav} ${styles.next}`}
            onClick={navigate(1)}
            mobileSmall={showResponsiveButtons}
            {...navButtonStyling}
          />

          {link && (
            <div className={styles.linkContainer}>
              {React.cloneElement(
                link,
                link.props,
                <ResponsiveButton>{link.props.children}</ResponsiveButton>,
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

const StyledLabeledIcon = styled(LabeledIcon)({
  svg: {
    fill: 'var(--color-light-red)',
    transformOrigin: 'center center',
    transform: 'scale(1.2)',
  },
});

const Container = styled('div')(({ theme }) => ({
  marginTop: '83px',

  [theme.breakpoints.up('md')]: {
    marginTop: '216px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  gridArea: 'title',
  textAlign: 'center',
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightMedium,
  margin: 'auto',
  marginBottom: '125px',
  marginTop: '0',
  maxWidth: '1110px', // max width based on design feedback

  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px',
    padding: '0px 38px',
  },
}));

const StepTitle = styled('p')(({ theme }) => ({
  textAlign: 'center',
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: '11px',
}));

const StepText = styled('p')(({ theme }) => ({
  textAlign: 'center',
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightRegular,
  opacity: '0.7',
}));

const ResponsiveButton = styled(Button)(({ theme }) => ({
  [`&.${buttonClasses.sizeLarge}`]: {
    ...theme.typography.copy,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

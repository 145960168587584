import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { MarkdownContainer } from './markdown-container';

type Props = {
  html: string;
  title: string;
};

export const MarkdownAccordion = ({ html, title }: Props) => {
  return (
    <Accordion>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>
        <MarkdownContainer html={html} />
      </AccordionDetails>
    </Accordion>
  );
};

import {
  allServiceSegments,
  AlreadyCustomer,
  Brochure,
  CompanySize,
  LeadIndustry,
  LeadIntent,
  ResponsePreference,
  ServiceSegmentType,
} from '@mewa/types';

import { FormState as FormStateB } from './components/a-b-test-form-variant/use-form-state';
import { FormState as FormStateA } from './components/use-form-state';

const FORM_STATE_KEY = 'mewa.form.state';
const BROCHURES_KEY = 'mewa.brochures-download.brochures';
const SERVICE_SEGMENT = 'mewa.conversion.service-segment';

export const persistFormState = ({
  formDataState: { legal, isValid, triggerValidation, ...formFields },
}: FormStateA | FormStateB) => {
  store(FORM_STATE_KEY, {
    formDataState: {
      ...Object.fromEntries(
        Object.entries(formFields).map(([name, field]) => [name, field.value]),
      ),
      // Don't save the legal checkbox, this should be checked actively
      // by the user every time the form should be sent.
      legal: undefined,
    },
  });
};

export const loadFormState = () => {
  const state = load(FORM_STATE_KEY);

  if (state) {
    const formState = {
      formDataState: {
        alreadyCustomer:
          (state.formDataState?.alreadyCustomer as AlreadyCustomer) || null,
        customerId: (state.formDataState?.customerId as string) || null,
        leadIntent: (state.formDataState?.leadIntent as LeadIntent) || null,
        industry: (state.formDataState?.industry as LeadIndustry) || null,
        companyName: (state.formDataState?.companyName as string) || null,
        companyZip: (state.formDataState?.companyZip as string) || null,
        size: (state.formDataState?.size as CompanySize) || null,
        firstName: (state.formDataState?.firstName as string) || null,
        lastName: (state.formDataState?.lastName as string) || null,
        email: (state.formDataState?.email as string) || '',
        phone: (state.formDataState?.phone as string) || null,
        message: (state.formDataState?.message as string) || '',
        serviceSegment:
          (state.formDataState?.serviceSegment as ServiceSegmentType) || null,
        responsePreference:
          (state.formDataState?.responsePreference as Record<
            ResponsePreference,
            boolean
          >) || null,
      },
    };

    return formState;
  }

  return null;
};

export const storeBrochures = (brochures: Brochure[]) => {
  store(BROCHURES_KEY, brochures);
};

export const clearBrochures = () => {
  clear(BROCHURES_KEY);
};

export const loadBrochures = (): Brochure[] | null => {
  const state = load(BROCHURES_KEY);

  if (state && Array.isArray(state)) {
    const res: Brochure[] = [];

    state.forEach((item) => {
      if (item === 'brandCatalog' || allServiceSegments.includes(item)) {
        res.push(item);
      }
    });

    return res;
  }

  return null;
};

export const storeServiceSegment = (serviceSegment: ServiceSegmentType) => {
  store(SERVICE_SEGMENT, serviceSegment);
};

export const clearServiceSegment = () => {
  clear(SERVICE_SEGMENT);
};

export const loadServiceSegment = (): ServiceSegmentType | null => {
  const state = load(SERVICE_SEGMENT);

  if (state && typeof state === 'string') {
    return state as ServiceSegmentType;
  }

  return null;
};

const store = (key: string, state: unknown) => {
  if (!hasSessionStorage()) {
    return;
  }

  window.sessionStorage.setItem(
    key,
    JSON.stringify({
      // Wrap in another object in case we want to store additional metadata
      // in the future.
      state: state,
    }),
  );
};

const load = (key: string) => {
  if (!hasSessionStorage()) {
    return null;
  }

  const data = window.sessionStorage.getItem(key);

  if (data) {
    const jsonData = JSON.parse(data);

    if (jsonData && jsonData.state) {
      return jsonData.state;
    }
  }

  return null;
};

const clear = (key: string) => {
  if (!hasSessionStorage()) {
    return;
  }

  window.sessionStorage.removeItem(key);
};

const hasSessionStorage = () => {
  return typeof window !== 'undefined' && !!window.sessionStorage;
};

import { styled } from '@mui/material';

export type AwardData = {
  title: string;
  description: string;
  image: React.ReactElement;
};

export const Award = ({ award }: { award: AwardData }) => (
  <AwardContainer>
    <ImageContainer>{award.image}</ImageContainer>
    <Title>{award.title}</Title>
    <Description>{award.description}</Description>
  </AwardContainer>
);

const AwardContainer = styled('div')({
  display: 'grid',
  gridTemplateRows: '330px 36px auto',
});

const ImageContainer = styled('div')({
  alignSelf: 'center',
  justifySelf: 'center',

  '.gatsby-image-wrapper': {
    width: '200px',
    height: '275px',
  },
});

const Title = styled('h3')(({ theme }) => ({
  ...theme.typography.copySm,
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'center',
  margin: 0,
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copySm,
  textAlign: 'center',
  maxWidth: '300px',
  justifySelf: 'center',
  margin: 0,
}));

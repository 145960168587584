import { Grid, styled, Theme } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';

export const StageWithImage = ({
  title,
  subtitle,
  image,
  award,
}: {
  title: string;
  subtitle: string;
  image: React.ReactElement;
  award: React.ReactElement;
}) => (
  <Container>
    <TextContainer>
      <ResponsiveGrid>
        <Grid item xs={12}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Grid>
      </ResponsiveGrid>
    </TextContainer>
    <PositionContainer>
      <ImageContainer>{image}</ImageContainer>
      <ResponsiveGrid>
        <Grid item>
          <AwardContainer>{award}</AwardContainer>
        </Grid>
      </ResponsiveGrid>
    </PositionContainer>
  </Container>
);

const TextContainer = styled('div')(({ theme }) => ({
  marginTop: '64px',
  marginBottom: '35px',

  [theme.breakpoints.up('sm')]: {
    marginTop: '84px',
  },

  [theme.breakpoints.up(1280)]: {
    marginTop: 0,
    position: 'absolute',
    top: 'clamp(80px, calc(14vw - 142.9px), 128px)',
    zIndex: 1,
  },
}));

const PositionContainer = styled('div')(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    aspectRatio: 64 / 25,
  },
}));

const Container = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const getHeadlineStyles = (theme: Theme) => ({
  ...theme.typography.h3,
  margin: 0,

  [theme.breakpoints.up(1280)]: {
    color: 'var(--color-white)',
  },
});

const Title = styled('h1')(({ theme }) => ({
  ...getHeadlineStyles(theme),
  fontWeight: theme.typography.fontWeightMedium,
}));

const Subtitle = styled('h2')(({ theme }) => ({
  ...getHeadlineStyles(theme),
  lineHeight: 1,
  fontWeight: theme.typography.fontWeightLight,
}));

const ImageContainer = styled('div')({
  img: { width: '100%' },
});

const AwardContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '30px',

  'img, .gatsby-image-wrapper': {
    width: 'clamp(80px, calc(10vw - 48px), 120px)',
  },

  [theme.breakpoints.up('sm')]: {
    bottom: '80px',
  },

  [theme.breakpoints.up(1280)]: {
    bottom: '40px',
  },

  [theme.breakpoints.up('md')]: {
    bottom: '60px',
  },
}));

import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { GatsbyLinkProps, Link } from 'gatsby';

import {
  ConversionButtonProps,
  PageHeadline,
  PageHeadlineProps,
} from '@mewa/ui';

type LinkProps = React.ComponentClass<GatsbyLinkProps<unknown>>;

export type PageHeadlineCTAProps = {
  label: string;
  link: string;
  display?: ConversionButtonProps<LinkProps>['display'];
  onClick?: () => void;
};

type Props = Omit<PageHeadlineProps<LinkProps>, 'cta'> & {
  cta?: PageHeadlineCTAProps;
};

export const PageHeadlineWrapper = ({ cta, ...props }: Props) => {
  const ctaProps: PageHeadlineProps<LinkProps>['cta'] | undefined = cta && {
    ...cta,
    onClick: () => {
      if (cta.onClick) {
        cta.onClick();
      }
      DataLayer.push({ event: 'cta_contact_subheadline' });
    },
    link: {
      component: Link,
      props: { to: cta?.link },
    },
  };

  return <PageHeadline {...props} cta={ctaProps} />;
};

import { useRef, useState } from 'react';

import { Button, Grid, styled } from '@mui/material';

import { SelectArrowDownIcon } from '../../../assets/icons';
import { ResponsiveGrid } from '../../layout/grid';
import { MarkdownContainer } from '../markdown/markdown-container';

type Props = {
  html: string;
  showMoreLabel: string;
  showLessLabel: string;
};

export const SeoFooter = ({ html, showMoreLabel, showLessLabel }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const expandButton = useRef<HTMLButtonElement>(null);
  const seoFooterRef = useRef<HTMLElement>(null);

  const toggleExpanded = () => {
    setIsExpanded((isExpanded) => !isExpanded);

    // scroll to seoFooter when we close the section
    if (isExpanded) {
      seoFooterRef.current?.scrollIntoView();
    }

    if (expandButton.current) {
      expandButton.current.blur();
    }
  };

  return (
    <aside ref={seoFooterRef}>
      <ResponsiveGrid
        sx={{ marginTop: ['10px', '40px'], marginBottom: ['40px', '100px'] }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <StyledMarkdownContainer html={html} isExpanded={isExpanded} />
          <Button
            color="secondary"
            size="medium"
            variant="text"
            endIcon={
              <StyledSelectArrowDownIcon
                sx={
                  isExpanded
                    ? { transform: 'rotate(180deg)' }
                    : { transform: 'rotate(0)' }
                }
              />
            }
            ref={expandButton}
            onClick={() => toggleExpanded()}
          >
            {isExpanded ? showLessLabel : showMoreLabel}
          </Button>
        </Grid>
      </ResponsiveGrid>
    </aside>
  );
};

const StyledMarkdownContainer = styled(MarkdownContainer)(
  ({ isExpanded }: { isExpanded: boolean }) =>
    ({ theme }) => ({
      [theme.breakpoints.up(0)]: {
        marginBottom: 0,
      },
      'p:first-of-type ~ *': { display: isExpanded ? undefined : 'none' },
      h3: {
        ...theme.typography.h6,
      },
    }),
);

const StyledSelectArrowDownIcon = styled(SelectArrowDownIcon)({
  color: 'var(--color-light-red)',
});
export default SeoFooter;

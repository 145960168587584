import { DataLayer } from '@piwikpro/react-piwik-pro';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Link as GatsbyLink, navigate } from 'gatsby';

import {
  buttonBaseClasses,
  buttonClasses,
  Grid,
  Link,
  linkClasses,
  styled,
} from '@mui/material';

import { Language } from '@mewa/types';
import {
  ConversionButton,
  cssVars,
  LoginIcon,
  ResponsiveGrid,
  StickyHeader,
  useCustomWindowEvent,
} from '@mewa/ui';

import { marketsConfig } from '../../../libs/config/markets';
import { getLocalizedMandatoryLink } from '../../../libs/config/markets-utils';
import t from '../../../libs/translate';
import { useConversionButtonLabel } from '../../a-b-testing/cta-label-experiment';
import { HeaderSearch } from '../../templates/search/header-search';
import MobileQuickNavigation from '../quick-navigation/mobile';
import CompanyMenu from './desktop/company-menu';
import HeaderMenu from './desktop/header-menu';
import {
  BaseNavigation,
  HeaderBaseProps,
  HeaderWrapper,
  MewaLogo,
  OPEN_QUICK_NAVIGATION_EVENT,
} from './header-base';
import MobileDefaultNavigation from './mobile/menu';
import MobileMenuLayer from './mobile/menu-layer';
import { LinkData } from './types';

type Props = HeaderBaseProps & {
  locationKey: string;
  locationSearch: string;
  lang: Language;
};

export const Header = ({
  langPrefix,
  navigationData,
  locationKey,
  locationSearch,
  market,
  lang,
  showCTA = true,
  featureDev,
}: Props) => {
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showCompanyMenu, setShowCompanyMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  // We have two kinds of navigation. The default navigation and the quick
  // navigation. The mobile viewport uses the same open/close layer animation
  // for those two navigation types. The `mobileNavigationType` allows us to
  // decide what kind of mobile navigation we want to render.
  //
  // The header only renders the mobile version of the quick navigation. The
  // desktop version is rendered inside the homepage template.
  const [mobileNavigationType, setMobileNavigationType] = useState<
    'default' | 'quick'
  >('quick');

  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const companyNavLinkRef = useRef<HTMLDivElement>(null);

  const onOpenQuickNav = useCallback(() => {
    setMobileNavigationType('quick');
    setShowMobileMenu(true);
  }, [setShowMobileMenu, setMobileNavigationType]);

  // This event is triggered from the homepage
  useCustomWindowEvent(OPEN_QUICK_NAVIGATION_EVENT, onOpenQuickNav);

  const hideMenus = () => {
    setShowProductMenu(false);
    setShowCompanyMenu(false);
    setShowMobileMenu(false);
  };

  // Close all menus every time the locationKey changes. This happens when we
  // navigate to a different page or even when we navigate to the current page
  // using a gatsby link.
  useEffect(() => {
    hideMenus();
  }, [locationKey, setShowCompanyMenu, setShowProductMenu, setShowMobileMenu]);

  const onToggleMobileMenu = () => {
    if (!showMobileMenu) {
      setMobileNavigationType('default');

      if (searchHasFocus) {
        setSearchHasFocus(false);
      }
    }

    setShowMobileMenu(!showMobileMenu);
  };

  const { ctaLabel } = useConversionButtonLabel({
    translations: navigationData.headerAndFooterTranslations,
  });

  const localizedLinks = navigationData.navigationLinks;

  const clothingLinks = navigationData?.navigationClothing?.nodes;

  const nonClothingLinks = navigationData?.navigationNonClothing?.nodes;

  const industryLinks = navigationData?.navigationIndustries?.nodes?.map(
    (industry) => {
      const firstClothingServiceSegment = industry.serviceSegments.find(
        (s) => s.category === 'MBS',
      );

      const slug = firstClothingServiceSegment
        ? industry.slug + '-' + firstClothingServiceSegment.slug
        : industry.slug +
          '-' +
          navigationData.navigationLinks.navigation.nonClothing.slug;

      return {
        name: industry.name,
        slug,
      };
    },
  );

  const companyLinks: LinkData[] = [
    {
      name: t(navigationData.headerAndFooterTranslations, 'footer_press'),
      slug: localizedLinks.pressOverview.slug,
    },
    {
      name: t(navigationData.headerAndFooterTranslations, 'footer_career'),
      slug: getLocalizedMandatoryLink('careerPortal', market, lang),
      isExternal: true,
      handleClick: () => {
        DataLayer.push({ event: 'external_career_header' });
      },
    },
  ];

  if (marketsConfig[market].socialMedia?.blog) {
    companyLinks.push({
      name: t(navigationData.headerAndFooterTranslations, 'footer_mewa_blog'),
      slug: marketsConfig[market].socialMedia.blog as string,
      isExternal: true,
    });
  }

  const hasProductNavigationData =
    clothingLinks || nonClothingLinks || industryLinks;

  const closeFlyoutWithDelay = () => {
    timeoutId.current = setTimeout(() => {
      setShowProductMenu(false);
      setShowCompanyMenu(false);
    }, 300);
  };

  const cancelFlyoutClosing = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
  };

  const trackCustomerPortal = () => {
    DataLayer.push({
      event: 'external_customerportal_header',
    });
  };

  useEffect(() => {
    if (searchHasFocus || showMobileMenu) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [searchHasFocus, showMobileMenu]);

  useEffect(() => {
    setSearchHasFocus(false);

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [locationKey]);

  const contactCtaOnClick = () => {
    DataLayer.push({ event: 'cta_contact_header' });
  };

  return (
    <StickyHeader
      className={searchHasFocus ? 'search-has-focus' : ''}
      onClick={(e) => {
        if (searchHasFocus) {
          if (!element.current?.contains(e.target as Node)) {
            setSearchHasFocus(false);
          }
        }
      }}
    >
      <ResponsiveGrid
        sx={wrapperStyles}
        gridProps={{
          sx: { height: '100%', backgroundColor: 'var(--color-white)' },
        }}
      >
        <Grid item xs={12}>
          <HeaderWrapper
            onClick={() => setShowProductMenu(false)}
            onMouseEnter={closeFlyoutWithDelay}
          >
            <MewaLogo langPrefix={langPrefix} />

            <SearchPositionContainer>
              <StyledHeaderSearch
                ref={element}
                backButtonLabel={t(
                  navigationData.headerAndFooterTranslations,
                  'general_form_button_label_back',
                )}
                label={t(
                  navigationData.searchPageTranslations,
                  'general_search',
                )}
                placeholder={t(
                  navigationData.searchPageTranslations,
                  'general_search_placeholder',
                )}
                translations={navigationData.searchPageTranslations}
                onSubmit={(searchTerm) => {
                  navigate(
                    `${langPrefix}/${
                      navigationData.navigationLinks.searchResultPage.slug
                    }/?q=${searchTerm.split(' ').join('+')}`,
                  );
                }}
                onSearchFocusChange={(hasFocus) => setSearchHasFocus(hasFocus)}
                locationSearch={locationSearch}
                serviceSegmentLinks={
                  navigationData.navigationServiceSegments.nodes
                }
                market={market}
                langPrefix={langPrefix}
                locationKey={locationKey}
              />
            </SearchPositionContainer>

            <DesktopNavigation>
              <MainNavigation>
                <NavLink
                  underline="none"
                  color="secondary"
                  to={`${langPrefix}/${localizedLinks.navigation.productMenu.slug}`}
                  component={GatsbyLink}
                  onMouseEnter={() => {
                    cancelFlyoutClosing();
                    setShowProductMenu(true);
                    setShowCompanyMenu(false);
                  }}
                  onMouseLeave={closeFlyoutWithDelay}
                >
                  <span>{localizedLinks.navigation.productMenu.name}</span>
                </NavLink>

                <NavLink
                  underline="none"
                  color="secondary"
                  to={`${langPrefix}/${localizedLinks.mewaPrinciple.slug}`}
                  component={GatsbyLink}
                >
                  <span>{localizedLinks.mewaPrinciple.name}</span>
                </NavLink>

                <div ref={companyNavLinkRef} style={{ height: '100%' }}>
                  <NavLink
                    underline="none"
                    color="secondary"
                    to={`${langPrefix}/${localizedLinks.navigation.company.slug}`}
                    component={GatsbyLink}
                    onMouseEnter={() => {
                      cancelFlyoutClosing();
                      setShowProductMenu(false);
                      setShowCompanyMenu(true);
                    }}
                    onMouseLeave={closeFlyoutWithDelay}
                  >
                    <span>{localizedLinks.navigation.company.name}</span>
                  </NavLink>
                </div>

                <CustomerPortalLink
                  underline="none"
                  color="secondary"
                  href={getLocalizedMandatoryLink(
                    'customerPortal',
                    market,
                    lang,
                  )}
                  target="_blank"
                  rel="noreferrer"
                  onClick={trackCustomerPortal}
                >
                  <span>
                    <LoginIcon />{' '}
                    {t(
                      navigationData.headerAndFooterTranslations,
                      'general_login',
                    )}
                  </span>
                </CustomerPortalLink>
              </MainNavigation>

              {showCTA ? (
                <StyledConversionButton
                  link={{
                    component: GatsbyLink,
                    props: {
                      to: `${langPrefix}/${navigationData.navigationLinks.conversion.contact.slug}`,
                    },
                  }}
                  variant="contained"
                  onClick={contactCtaOnClick}
                >
                  {ctaLabel}
                </StyledConversionButton>
              ) : null}

              <BurgerMenu
                underline="none"
                color="secondary"
                onClick={onToggleMobileMenu}
                active={showMobileMenu}
                tabIndex={0}
              >
                {t(
                  navigationData.headerAndFooterTranslations,
                  'burger_menu_title',
                )}
                <div>
                  <span />
                  <span className="center" />
                  <span />
                </div>
              </BurgerMenu>
            </DesktopNavigation>
          </HeaderWrapper>
        </Grid>
      </ResponsiveGrid>

      {!showCompanyMenu && hasProductNavigationData && (
        <HeaderMenu
          langPrefix={langPrefix}
          show={showProductMenu}
          onMouseEnter={cancelFlyoutClosing}
          columnData={[
            {
              title: navigationData.navigationLinks.navigation.clothing.name,
              slug: featureDev
                ? navigationData.navigationLinks.navigation.clothing.slug
                : undefined,
              links: clothingLinks,
            },
            {
              title: navigationData.navigationLinks.navigation.nonClothing.name,
              slug: featureDev
                ? navigationData.navigationLinks.navigation.nonClothing.slug
                : undefined,
              links: nonClothingLinks,
            },
            {
              title: navigationData.navigationLinks.navigation.industries.name,
              slug: featureDev
                ? navigationData.navigationLinks.navigation.industries.slug
                : undefined,
              links: industryLinks,
            },
          ]}
        />
      )}

      {!showProductMenu && companyNavLinkRef.current && (
        <CompanyMenu
          targetElement={companyNavLinkRef.current}
          langPrefix={langPrefix}
          show={showCompanyMenu}
          onMouseEnter={cancelFlyoutClosing}
          columnData={{ links: companyLinks }}
        />
      )}

      <MobileMenuLayer show={showMobileMenu}>
        {mobileNavigationType === 'default' && (
          <MobileDefaultNavigation
            translations={navigationData.headerAndFooterTranslations}
            langPrefix={langPrefix}
            companyLinks={companyLinks}
            clothingLinks={clothingLinks}
            nonClothingLinks={nonClothingLinks}
            industryLinks={industryLinks}
            consultation={{
              onClick: contactCtaOnClick,
              slug: `${langPrefix}/${navigationData.navigationLinks.conversion.contact.slug}`,
            }}
            navigationLinks={navigationData.navigationLinks}
            market={market}
            lang={lang}
            featureDev={featureDev}
          />
        )}
        {mobileNavigationType === 'quick' && hasProductNavigationData && (
          <MobileQuickNavigation
            langPrefix={langPrefix}
            industries={navigationData.navigationIndustries.nodes}
            serviceSegments={navigationData.navigationServiceSegments.nodes}
            translations={{
              ...navigationData.headerAndFooterTranslations,
              ...navigationData.searchPageTranslations,
            }}
            productOverviewSlug={
              navigationData.navigationLinks.navigation.productMenu.slug
            }
            nonClothingSlug={
              navigationData.navigationLinks.navigation.nonClothing.slug
            }
          />
        )}
      </MobileMenuLayer>

      {(showProductMenu || showCompanyMenu) && (
        <DesktopMenuDismissArea
          onMouseEnter={() => {
            setShowProductMenu(false);
            setShowCompanyMenu(false);
          }}
        />
      )}
    </StickyHeader>
  );
};

const SearchPositionContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  gridRow: '2 / span 1',
  gridColumn: '1 / span 2',

  '.search-has-focus &': {
    zIndex: 1,
  },

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    gridRow: 'auto',
    gridColumn: 'auto',
    paddingRight: '20px',
    maxWidth: '335px',
  },

  [theme.breakpoints.up(972)]: {
    maxWidth: '350px',
  },

  [theme.breakpoints.up(1680)]: {
    maxWidth: '400px',
  },
}));

const StyledHeaderSearch = styled(HeaderSearch)(({ theme }) => ({
  width: '100%',
  marginBottom: '14px',
  transition: 'width var(--transition-duration)',
  position: 'absolute',

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    marginBottom: 0,
  },

  '.search-has-focus &': {
    marginTop: '20px',

    [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
      width: '450px',
      left: 0,
      marginTop: 0,
    },

    [theme.breakpoints.up('mdSm')]: {
      width: '550px',
    },

    [theme.breakpoints.up('md')]: {
      width: '650px',
    },
  },
}));

const DesktopNavigation = styled(BaseNavigation)(({ theme }) => ({
  alignItems: 'center',

  '.search-has-focus': {
    zIndex: 0,
  },

  [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
    paddingLeft: '20px',
  },

  [`& .${linkClasses.root}`]: {
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.up(cssVars.mobileSearchMaxWidth)]: {
      fontSize: theme.typography.copySm.fontSize,
    },

    [theme.breakpoints.up(1680)]: {
      fontSize: theme.typography.copy.fontSize,
    },
  },
}));

const DesktopMenuDismissArea = styled('div')({
  position: 'fixed',
  left: 0,
  width: '100%',
  top: 0,
  height: '100%',
  zIndex: -1,
});

const BurgerMenu = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ active, theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: '10px',

  [theme.breakpoints.up(972)]: {
    flexDirection: 'row-reverse',
    marginLeft: '30px',
  },

  '> div': {
    width: '24px',
    height: '30px',
    padding: '6px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  'div span': {
    display: 'block',
    width: '100%',
    height: '2px',
    backgroundColor: 'currentColor',
    color: 'inherit',
    transition: 'transform 200ms ease, opacity 200ms',
  },

  ...(active && {
    zIndex: 1,
    span: {
      borderRadius: '1px',
    },
    'span:first-of-type': {
      transform: 'translate(1px, 8px) rotate(45deg)',
    },
    'span:last-of-type': {
      transform: 'translate(1px, -8px) rotate(-45deg)',
    },

    '> div > span.center': {
      opacity: 0,
    },
  }),

  [theme.breakpoints.up(cssVars.headerMobileMaxWidth)]: {
    display: 'none',
  },

  [theme.breakpoints.down(cssVars.mobileSearchMaxWidth)]: {
    '.search-has-focus &': {
      display: 'none',
    },
  },
}));

const linkTransitionCfg = 'var(--transition-duration) var(--transition-timing)';

const MainNavigation = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  height: '100%',
  alignItems: 'center',
});

const NavLink = styled(Link)(({ theme }) => ({
  marginRight: '30px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down(cssVars.headerMobileMaxWidth)]: {
    display: 'none',
  },

  [theme.breakpoints.up(1680)]: {
    marginRight: '40px',
  },

  span: {
    borderRadius: '1px',
    outlineOffset: '6px',
  },

  '&:focus-visible': {
    outline: 'none',

    span: {
      outline: '1px solid var(--color-dark-coal)',
    },
  },
})) as typeof Link;

const CustomerPortalLink = styled(NavLink)({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '3px',

  svg: {
    marginRight: '5px',
    stroke: 'var(--color-dark-coal)',
    strokeWidth: 2,
    transition: `stroke ${linkTransitionCfg}`,
  },
});

const wrapperStyles = {
  height: '100%',
  backgroundColor: 'white',
};

const StyledConversionButton = styled(ConversionButton)(({ theme }) => ({
  [`&.${buttonBaseClasses.root}.${buttonClasses.root}`]: {
    marginTop: 0,
    display: 'none',

    [theme.breakpoints.up(972)]: {
      display: 'block',
      marginRight: '16px',
    },
  },
}));

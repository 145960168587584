import React from 'react';

import { Button, ButtonProps, styled } from '@mui/material';

const SubmitButton: React.FC<ButtonProps> = ({ children, ...rest }) => (
  <StyledButton variant="contained" {...rest}>
    {children}
  </StyledButton>
);

export default SubmitButton;

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
}));

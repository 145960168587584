import { Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import PhoneNumberLink from './phone-number-link';

type Props = {
  title: string;
  phoneNumber: string;
  officeHours: string;
};

export const PhoneContactTeaser = ({
  title,
  phoneNumber,
  officeHours,
}: Props) => (
  <Container>
    <GridSpacer item sm={1} />
    <Grid item xs={11} sm={5}>
      <Headline>{title}</Headline>
    </Grid>

    <GridSpacer
      item
      sm={1}
      sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}
    />
    <Grid item xs={11} sm={5}>
      <PhoneNumberLink phoneNumber={phoneNumber} officeHours={officeHours} />
    </Grid>
  </Container>
);

export default PhoneContactTeaser;

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  paddingTop: '142px',
  paddingBottom: '142px',

  [theme.breakpoints.down('md')]: {
    paddingTop: '46px',
    paddingBottom: '58px',
  },
}));

const GridSpacer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const Headline = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  margin: 0,
  maxWidth: '410px',

  [theme.breakpoints.up('md')]: {
    whiteSpace: 'pre-line',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: '30px',
  },
}));

import { useEffect, useRef } from 'react';

type EventCallback = (evt: CustomEvent) => void;

export const useCustomWindowEvent = (
  event: string,
  callback: EventCallback,
) => {
  const oldCallback = useRef<EventCallback | null>(null);

  useEffect(() => {
    if (oldCallback.current) {
      window.removeEventListener(event, oldCallback.current as EventListener);
    }

    window.addEventListener(event, callback as EventListener);
    oldCallback.current = callback;

    return () => {
      if (oldCallback.current) {
        window.removeEventListener(event, oldCallback.current as EventListener);
      }
    };
  }, [oldCallback, callback, event]);
};

import React from 'react';

import { Grid, styled, SxProps, Theme, useTheme } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import {
  ConversionButton,
  ConversionButtonProps,
} from '../conversion-button/conversion-button';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type PageHeadlineProps<T extends React.ElementType> = {
  title: string;
  component?: HeadingType;
  subtitle?: string | null;
  largerMobile?: boolean;
  sx?: SxProps<Theme>;
  cta?: ConversionButtonProps<T> & {
    label: string;
  };
  className?: string;
};

export const PageHeadline = <T extends React.ElementType>({
  title,
  component = 'h1',
  subtitle,
  largerMobile,
  sx = [],
  cta,
  className,
}: PageHeadlineProps<T>) => {
  const theme = useTheme();

  return (
    <ResponsiveGrid
      className={className}
      sx={[
        {
          marginTop: '176px',
          marginBottom: '200px',

          [theme.breakpoints.down('sm')]: {
            marginTop: '64px',
            marginBottom: '35px',
          },
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Grid item xs={11}>
        <Heading component={component} largerMobile={!!largerMobile}>
          {title}
        </Heading>

        {subtitle && (
          <Subline largerMobile={!!largerMobile}>{subtitle}</Subline>
        )}
      </Grid>
      {cta && (
        <Grid item xs={12}>
          <ConversionButton {...cta} variant="contained">
            {cta.label}
          </ConversionButton>
        </Grid>
      )}
    </ResponsiveGrid>
  );
};

const Heading = ({
  component,
  largerMobile,
  children,
}: React.PropsWithChildren<{
  component: HeadingType;
  largerMobile: boolean;
}>) => {
  const CustomHeading = styled(component, {
    shouldForwardProp: (prop) => prop !== 'largerMobile',
  })<{ largerMobile: boolean }>(({ theme, largerMobile }) => ({
    ...headlineElementStyles(
      theme,
      largerMobile,
      theme.typography.fontWeightMedium,
    ),
  }));

  return <CustomHeading largerMobile={largerMobile}>{children}</CustomHeading>;
};

const Subline = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'largerMobile',
})<{ largerMobile: boolean }>(({ theme, largerMobile }) => ({
  ...headlineElementStyles(
    theme,
    largerMobile,
    theme.typography.fontWeightLight,
    largerMobile
      ? theme.typography.fontWeightLight
      : theme.typography.fontWeightRegular,
  ),
}));

const headlineElementStyles = (
  theme: Theme,
  largerMobile: boolean,
  defaultFontWeight: React.CSSProperties['fontWeight'],
  mobileFontWeight?: React.CSSProperties['fontWeight'],
) => ({
  ...theme.typography.h1,
  margin: 0,

  [theme.breakpoints.down('md')]: {
    ...theme.typography.h2,
  },
});

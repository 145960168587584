import { Grid, styled } from '@mui/material';

import { ServiceTeaserType } from '@mewa/types';

import {
  CleaningClothsServiceTestIcon,
  LogoEmbroideryServiceIcon,
} from '../../../../assets/icons';
import ResponsiveGrid from '../../../layout/grid';
import styles from './service-teaser.module.scss';

export type ServiceTeaserProps = {
  title: string;
  description: string;
  type: ServiceTeaserType;
  link?: React.ReactNode;
  [key: `data-${string}`]: string;
};

const icons = {
  [ServiceTeaserType.cleaningClothsServiceTest]: CleaningClothsServiceTestIcon,
  [ServiceTeaserType.logoEmbroideryService]: LogoEmbroideryServiceIcon,
};

export const ServiceTeaser = ({
  title,
  description,
  type,
  link,
  ...dataProps
}: ServiceTeaserProps) => {
  const Icon = icons[type];

  return Icon ? (
    <Container>
      <Grid item xs={0} sm={2} md={3} />
      <Grid item xs={12} sm={8} md={6}>
        <div className={styles.serviceTeaser} {...dataProps}>
          <Icon className={styles.serviceTeaserIcon}></Icon>
          <Title>{title}</Title>
          <Description>{description}</Description>
          {link && <LinkWrapper>{link}</LinkWrapper>}
        </div>
      </Grid>
    </Container>
  ) : null;
};

const Container = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '50px',
  marginBottom: '66px',

  ':last-of-type': {
    marginBottom: 0,
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: '104px',
    marginBottom: '148px',
  },
}));

const Title = styled('h4')(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: '16px',
  marginBottom: 0,
}));

const Description = styled('p')(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: '12px',
  marginBottom: 0,

  [theme.breakpoints.up('sm')]: {
    marginTop: '24px',
  },
}));

const LinkWrapper = styled('div')({
  marginTop: '20px',
});

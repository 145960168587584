import React from 'react';

import { Breadcrumbs, Grid, Link, styled, useTheme } from '@mui/material';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import { ResponsiveGrid } from '../../layout/grid';
import { Line } from '../lines/line';

export type ProductBreadCrumbsProps = {
  LinkComponent: React.ElementType;
  data: BreadCrumbData[];
  mobileData?: BreadCrumbData;
};

export type BreadCrumbData = {
  name: string;
  slug: string;
};

export const ProductBreadCrumbs = ({
  data,
  mobileData,
  LinkComponent,
}: React.PropsWithChildren<ProductBreadCrumbsProps>) => {
  const theme = useTheme();

  return (
    <ResponsiveGrid>
      <Grid item xs={12}>
        <Line sx={{ display: ['none', 'block'] }} />

        {mobileData !== undefined && (
          <MobileBreadCrumb aria-label="Breadcrumb">
            <Link
              underline="none"
              color="secondary"
              component={LinkComponent}
              to={mobileData.slug}
              sx={{
                ...theme.typography.copySm,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              <ArrowLeftIcon
                color="var(--color-medium-grey)"
                height={'7px'}
                width={'7px'}
                style={{ marginRight: '12px' }}
              />

              {mobileData.name}
            </Link>
          </MobileBreadCrumb>
        )}

        <Breadcrumbs
          sx={{
            marginTop: '20px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.only('xs')]: {
              display: 'none',
            },
          }}
          separator={
            <ArrowRightIcon
              color="var(--color-medium-grey)"
              height={'8px'}
              width={'5px'}
              style={{ marginTop: '3px' }}
            />
          }
          aria-label="Breadcrumb"
        >
          {data.map((linkData) =>
            linkData.slug ? (
              <Link
                key={linkData.name}
                underline="none"
                color={'secondary'}
                component={LinkComponent}
                to={linkData.slug}
                sx={{ ...theme.typography.copySm }}
              >
                {linkData.name}
              </Link>
            ) : (
              <span
                key={linkData.name}
                style={{
                  color: 'var(--color-dark-coal)',
                  ...theme.typography.copySm,
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {linkData.name}
              </span>
            ),
          )}
        </Breadcrumbs>
      </Grid>
    </ResponsiveGrid>
  );
};

const MobileBreadCrumb = styled('ol')(({ theme }) => ({
  ...theme.typography.copySm,
  display: 'none',
  marginTop: '18px',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: 0,

  [theme.breakpoints.only('xs')]: {
    display: 'flex',
  },
}));

export default ProductBreadCrumbs;

import { styled, useMediaQuery, useTheme } from '@mui/material';

import { MarkdownContainer } from '../markdown/markdown-container';
import { MobileSlider } from '../mobile-slider/mobile-slider';

export type CostItem = {
  headline: string;
  description: {
    data: {
      childMarkdownRemark: {
        html: string;
      };
    };
  };
};

export const CostSection = ({ items }: { items: CostItem[] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderedItems = items.map((item, i) => (
    <ItemContainer key={i}>
      <Headline>{item.headline}</Headline>
      <StyledMarkdownContainer
        html={item.description.data.childMarkdownRemark.html}
      />
    </ItemContainer>
  ));

  return isMobile ? (
    <MobileSlider>{renderedItems}</MobileSlider>
  ) : (
    <StyledGrid>{renderedItems}</StyledGrid>
  );
};

export default CostSection;

const StyledGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  rowGap: '50px',
  columnGap: '100px',
  margin: '62px auto 20px 8px',
  gridTemplateColumns: `repeat(2, 1fr)`,

  [theme.breakpoints.between(1280, 1440)]: {
    gridTemplateColumns: `repeat(1, 1fr)`,
  },
}));

const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '42px',

  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginTop: 0,
  },
}));

const Headline = styled('h3')(({ theme }) => ({
  ...theme.typography.copy,
  fontWeight: theme.typography.fontWeightMedium,
  position: 'relative',
  marginTop: 0,
  marginBottom: 0,

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },

  [theme.breakpoints.up('sm')]: {
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      height: '13px',
      width: '2px',
      content: '""',
      backgroundColor: 'var(--color-light-red)',
      top: '6px',
      transform: 'translateX(-8px)',
    },
  },
}));

const StyledMarkdownContainer = styled(MarkdownContainer)(({ theme }) => ({
  ...theme.typography.copy,
  marginTop: '5px',

  [theme.breakpoints.up('xs')]: {
    marginBottom: 0, // overwrite marginBottom for all media queries
  },

  [theme.breakpoints.up('sm')]: {
    marginTop: '10px',
  },

  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px',
    p: {
      textAlign: 'center',
    },
  },

  'p:first-of-type': {
    marginTop: 0,
  },

  'p:last-of-type': {
    marginBottom: 0,
  },
}));

import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { Button, buttonClasses, Grid, styled, SxProps } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { MarkdownContainer } from '../../markdown/markdown-container';
import { ConversionTeaserBase } from '../types';

export type ConversionTeaserProps = {
  state?: Record<string, unknown>;
  sx?: SxProps;
};

export const ConversionTeaser = <T extends React.ElementType>({
  headline,
  labelCTA,
  piwikEventNameCTA,
  content,
  state,
  sx,
  image,
  link,
}: ConversionTeaserBase<T> & ConversionTeaserProps) => (
  <Container sx={sx}>
    {image}
    <TeaserResponsiveGrid gridProps={{ sx: { height: '100%' } }}>
      <TeaserGrid item xs={12} sm={9} md={12}>
        <TeaserBox hasContent={!!content}>
          <Grid item xs={11}>
            <Headline hasContent={!!content}>{headline}</Headline>
          </Grid>
          {content && (
            <Grid item xs={12} sm={11}>
              <ContentWrapper html={content} />
            </Grid>
          )}

          <Grid item xs={12}>
            <ButtonStyleWrapper>
              <Button
                variant="outlined"
                size="large"
                color="inverted"
                component={link.component}
                {...link.props}
                state={state}
                onClick={() =>
                  DataLayer.push({
                    event: piwikEventNameCTA || 'cta_contact_teaser',
                  })
                }
              >
                {labelCTA}
              </Button>
            </ButtonStyleWrapper>
          </Grid>
        </TeaserBox>
      </TeaserGrid>
    </TeaserResponsiveGrid>
  </Container>
);

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '75px',

  [theme.breakpoints.up('md')]: {
    marginTop: '150px',
  },
}));

const TeaserResponsiveGrid = styled(ResponsiveGrid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'var(--color-light-red)',
    paddingTop: '20px',
  },

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

const TeaserGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
  },
}));

const TeaserBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasContent',
})(({ hasContent }: { hasContent?: boolean }) => ({ theme }) => ({
  backgroundColor: 'var(--color-light-red)',

  paddingBottom: hasContent ? '60px' : '88px',

  [theme.breakpoints.up('sm')]: {
    paddingBottom: hasContent ? '80px' : '88px',
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '552px',
    padding: `60px 67px ${hasContent ? '106px' : '187px'} 57px`,
  },
}));

const Headline = styled('h3', {
  shouldForwardProp: (prop) => prop !== 'hasContent',
})(({ hasContent }: { hasContent?: boolean }) => ({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightMedium,
  color: 'var(--color-white)',
  marginTop: 0,
  marginBottom: '44px',

  [theme.breakpoints.down('md')]: {
    marginBottom: hasContent ? '17px' : '34px',
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: hasContent ? '22px' : '34px',
  },
}));

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  [`.${buttonClasses.outlined}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const ContentWrapper = styled(MarkdownContainer)(({ theme }) => ({
  color: 'var(--color-white)',

  [theme.breakpoints.up('xs')]: {
    marginBottom: '50px',
    paddingRight: '30px',
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '55px',
    padding: 0,
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '60px',
  },
}));

import React from 'react';

import { ServiceSegmentsTags } from './service-segments-tags';
import { Tags } from './types';

export const ServiceSegment = ({
  children,
  tags,
  category,
  theme,
}: {
  children: React.ReactElement;
  tags: Tags[];
  category: string;
  theme: 'dark' | 'light';
}) => {
  return (
    <>
      {children}
      <ServiceSegmentsTags theme={theme} tags={tags} category={category} />
    </>
  );
};

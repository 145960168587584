import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import {
  Button,
  buttonClasses,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { MarkdownContainer } from '../../markdown/markdown-container';
import { ConversionTeaserProps } from '../conversion-teaser/conversion-teaser';
import { ConversionTeaserBase } from '../types';

export type ConversionTeaserImageProps<T extends React.ElementType> = Omit<
  ConversionTeaserBase<T> & ConversionTeaserProps,
  'headline'
> & {
  headline: React.ReactElement;
  mobileImage: React.ReactElement;
};

export const ConversionTeaserImage = <T extends React.ElementType>({
  headline,
  labelCTA,
  piwikEventNameCTA,
  content,
  state,
  sx,
  image,
  mobileImage,
  link,
}: ConversionTeaserImageProps<T>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container sx={sx}>
      {isMobile ? mobileImage : image}
      <TeaserResponsiveGrid
        gridProps={{ sx: { height: '100%', justifyContent: 'flex-end' } }}
      >
        <TeaserGrid item xs={12} sm={6}>
          <TeaserBox>
            <Grid item xs={11}>
              <Headline>{headline}</Headline>
            </Grid>
            <Grid item xs={12} sm={11}>
              <ContentWrapper html={content} />
            </Grid>

            <Grid item xs={12}>
              <ButtonStyleWrapper>
                <Button
                  variant="contained"
                  color="primary"
                  component={link.component}
                  {...link.props}
                  state={state}
                  onClick={() =>
                    DataLayer.push({
                      event: piwikEventNameCTA || '',
                    })
                  }
                >
                  {labelCTA}
                </Button>
              </ButtonStyleWrapper>
            </Grid>
          </TeaserBox>
        </TeaserGrid>
      </TeaserResponsiveGrid>
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '75px',
  marginBottom: '60px',

  [theme.breakpoints.up('md')]: {
    marginTop: '150px',
  },
}));

const TeaserResponsiveGrid = styled(ResponsiveGrid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}));

const TeaserGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    height: '100%',
    marginTop: '50px',
  },

  [theme.breakpoints.up('mdSm')]: {
    marginTop: '70px',
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '100px',
  },
}));

const TeaserBox = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: '552px',
  },
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: 0,
  marginBottom: '5px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '8px',
    color: 'var(--color-white)',
  },
}));

const ButtonStyleWrapper = styled('div')(({ theme }) => ({
  [`.${buttonClasses.contained}`]: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '18px',
    },
  },
}));

const ContentWrapper = styled(MarkdownContainer)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightRegular,
  marginBottom: '20px',
  paddingRight: '30px',

  [theme.breakpoints.up('sm')]: {
    marginBottom: '30px',
    padding: 0,
    color: 'var(--color-white)',
  },
}));

import {
  CognitoIdentityClient,
  GetIdCommand,
  GetOpenIdTokenCommand,
} from '@aws-sdk/client-cognito-identity';
import {
  EvaluateFeatureCommand,
  EvidentlyClient,
  PutProjectEventsCommand,
} from '@aws-sdk/client-evidently';
import {
  AssumeRoleWithWebIdentityCommand,
  STSClient,
} from '@aws-sdk/client-sts';

import {
  identityPoolId,
  projektName,
  roleArn,
  roleSessionName,
  userIdKey,
} from './constants';

let client: EvidentlyClient | null = null;

let userId: string | null = null;
let sessionId: string | null = null;

export const getUserId = () => {
  if (userId !== null) {
    return userId;
  } else {
    const userIdFronSessionStorage = sessionStorage.getItem(userIdKey);
    if (!userIdFronSessionStorage) {
      userId = new Date().getTime().toString();

      sessionStorage.setItem(userIdKey, userId);
    } else {
      userId = userIdFronSessionStorage;
    }

    return userId;
  }
};

export const getSessionId = () => {
  if (sessionId !== null) {
    return sessionId;
  } else {
    sessionId = new Date().getTime().toString();

    return sessionId;
  }
};

const stsClient = new STSClient({ region: 'eu-central-1' });

export const getClient = async () => {
  const config = {
    evidently: {
      REGION: 'eu-central-1',
      ENDPOINT: 'https://evidently.eu-central-1.amazonaws.com',
    },
  };
  if (client !== null) {
    return client;
  } else {
    try {
      const credentials = await getCredentials();

      client = new EvidentlyClient({
        endpoint: config.evidently.ENDPOINT,
        region: config.evidently.REGION,
        credentials: {
          accessKeyId: credentials?.AccessKeyId ?? '',
          secretAccessKey: credentials?.SecretAccessKey ?? '',
          sessionToken: credentials?.SessionToken ?? '',
        },
      });
      return client;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
};

const cognitoIdentityClient = new CognitoIdentityClient({
  region: 'eu-central-1',
});

const getCredentials = async () => {
  return cognitoIdentityClient
    .send(new GetIdCommand({ IdentityPoolId: identityPoolId }))
    .then(async (res) =>
      cognitoIdentityClient.send(
        new GetOpenIdTokenCommand({
          IdentityId: res.IdentityId,
        }),
      ),
    )
    .then(async (res) =>
      stsClient.send(
        new AssumeRoleWithWebIdentityCommand({
          RoleArn: roleArn,
          RoleSessionName: roleSessionName,
          WebIdentityToken: res.Token,
        }),
      ),
    )
    .then((res) => res.Credentials);
};

export const evaluateFeature = async (feature: string) => {
  const client = await getClient();

  if (client) {
    return client.send(
      new EvaluateFeatureCommand({
        project: projektName,
        feature: feature,
        entityId: getUserId(),
      }),
    );
  } else {
    return null;
  }
};

export const putProjectEvent = async (data: string) => {
  await (
    await getClient()
  )?.send(
    new PutProjectEventsCommand({
      project: projektName,
      events: [
        {
          timestamp: new Date(),
          type: 'aws.evidently.custom',
          data: JSON.parse(data),
        },
      ],
    }),
  );
};

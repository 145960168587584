import { useEffect, useState } from 'react';

import { Market } from '@mewa/types';

import {
  evaluateFeature,
  getSessionId,
  getUserId,
  putProjectEvent,
} from './evidently-connection';

const featureNameMap = { desktop: 'homeStage', mobile: 'homeStageMobile' };

export enum StageVariant {
  old = 'old_stage',
  new = 'new_stage',
}

const DEFAULT_VALUE = StageVariant.old;

const experimentData: {
  variant: StageVariant | null;
  resultHasBeenSent: boolean;
  isLoadingExperiment: boolean;
  loadingPromise: Promise<StageVariant> | null;
} = {
  variant: null,
  resultHasBeenSent: true,
  isLoadingExperiment: false,
  loadingPromise: null,
};

export const useStageVariant = (market: Market) => {
  const [stageVariant, setFormVariant] = useState(DEFAULT_VALUE);
  const [isLoading, setIsLoading] = useState(market === Market.DE);

  useEffect(() => {
    if (market === Market.DE) {
      getStageVariant().then((variant) => {
        if (variant !== null) {
          experimentData.resultHasBeenSent = false;
          setFormVariant(variant);
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [market]);

  return { stageVariant, isLoading };
};

export const getStageVariant = async (): Promise<StageVariant | null> => {
  if (experimentData.variant !== null) {
    return Promise.resolve(experimentData.variant);
  } else {
    if (!experimentData.isLoadingExperiment) {
      return loadFeature();
    } else if (
      experimentData.isLoadingExperiment &&
      experimentData.loadingPromise !== null
    ) {
      return experimentData.loadingPromise;
    } else {
      return null;
    }
  }
};

const loadFeature = async (): Promise<StageVariant> => {
  const mobileQuery = `(max-width:900px)`;
  const isMobile = matchMedia(mobileQuery).matches;

  const featureName = featureNameMap[isMobile ? 'mobile' : 'desktop'];
  experimentData.isLoadingExperiment = true;

  experimentData.loadingPromise = evaluateFeature(featureName)
    .catch((e) => {
      console.log(`Feature "${featureName}" not found. Using default variant.`);
      return null;
    })
    .then((res) => {
      experimentData.isLoadingExperiment = false;
      experimentData.loadingPromise = null;
      if (res === null || res.value?.stringValue === undefined) {
        return DEFAULT_VALUE;
      } else {
        experimentData.variant = res.value.stringValue as StageVariant;
        return experimentData.variant;
      }
    });

  return experimentData.loadingPromise;
};

export const reset = async () => {
  if (!experimentData.resultHasBeenSent) {
    await sendEvent(false);
  }
  experimentData.resultHasBeenSent = false;
};

export const sendEvent = async (result: boolean) => {
  if (!experimentData.resultHasBeenSent) {
    const userId = getUserId();
    const sessionId = getSessionId();

    experimentData.resultHasBeenSent = true;
    const quickNavResult = JSON.stringify({
      details: {
        quickNavUsed: result ? 1 : 0,
      },
      userDetails: { userId, sessionId },
    });

    await putProjectEvent(quickNavResult);
  }
};

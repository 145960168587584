import { DataLayer } from '@piwikpro/react-piwik-pro';
import React from 'react';

import { Button, Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../../layout/grid';
import { MarkdownContainer } from '../../markdown/markdown-container';
import { ConversionTeaserBase } from '../types';

export const ConversionTeaserLight = <T extends React.ElementType>({
  headline,
  content,
  labelCTA,
  link,
  piwikEventNameCTA,
  image,
}: ConversionTeaserBase<T>) => (
  <ConversionContainer>
    <Grid item xs={12} md={7}>
      {image}
    </Grid>
    <GridSpacer item md={1} />
    <Grid item xs={12} md={4}>
      <ConversionTitle>{headline}</ConversionTitle>
      <ConversionContent html={content} />
      <Button
        component={link.component}
        {...link.props}
        variant="outlined"
        color="primary"
        sx={{ width: ['100%', 'auto'] }}
        onClick={() => DataLayer.push({ event: piwikEventNameCTA })}
      >
        {labelCTA}
      </Button>
    </Grid>
  </ConversionContainer>
);

const ConversionContainer = styled(ResponsiveGrid)(({ theme }) => ({
  marginTop: '140px',

  [theme.breakpoints.down('md')]: {
    marginTop: '80px',
  },
}));

const GridSpacer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const ConversionTitle = styled('h2')(({ theme }) => ({
  ...theme.typography.h3,
  marginTop: '80px',
  marginBottom: '37px',
  width: '90%',

  [theme.breakpoints.down('md')]: {
    marginTop: '50px',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '40px',
    marginBottom: '30px',
  },
}));

const ConversionContent = styled(MarkdownContainer)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginBottom: '50px',
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: '55px',
  },

  [theme.breakpoints.up('md')]: {
    marginBottom: '60px',
  },
}));

import React from 'react';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Grid, styled } from '@mui/material';

import ResponsiveGrid from '../../layout/grid';
import { FadeImages } from './fade-images';
import { ServiceSegment } from './service-segment';
import { TileImages } from './tile-images';
import { SlideData, Tags } from './types';

type Props = {
  slidesLeft: SlideData[];
  slidesRight: SlideData[];
  tagsLeft: Tags[];
  tagsRight: Tags[];
  headline: string;
  categoryLeft: string;
  categoryRight: string;
  theme?: 'light' | 'dark';
  fadeDelay?: number;
};

export const ServiceSegmentsOverview = ({
  slidesLeft,
  slidesRight,
  tagsLeft,
  tagsRight,
  headline,
  categoryLeft,
  categoryRight,
  theme = 'dark',
  fadeDelay = 3000,
}: Props) => {
  return (
    <Wrapper
      sx={{
        backgroundColor: theme === 'dark' ? '#515151' : '#F3F3F3',
      }}
      gridProps={{ justifyContent: 'center' }}
    >
      <Grid item xs={12}>
        <Headline
          sx={{
            color: theme === 'dark' ? '#fff' : '#000',
          }}
        >
          {headline}
        </Headline>
      </Grid>

      {slidesLeft.length > 0 && (
        <Grid item xs={12} sm={6}>
          <ServiceSegment category={categoryLeft} tags={tagsLeft} theme={theme}>
            {slidesLeft.length <= 4 ? (
              <FadeImages slides={slidesLeft} fadeDelay={fadeDelay} />
            ) : (
              <TileImages slides={slidesLeft} />
            )}
          </ServiceSegment>
        </Grid>
      )}

      {slidesRight.length > 0 && (
        <Grid item xs={12} sm={6}>
          <ServiceSegment
            theme={theme}
            tags={tagsRight}
            category={categoryRight}
          >
            <FadeImages slides={slidesRight} />
          </ServiceSegment>
        </Grid>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ResponsiveGrid)(({ theme }) => ({
  paddingBottom: '90px',
  paddingTop: '50px',
}));

const Headline = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  textAlign: 'center',
}));
